<div class="container mt-3">
    <h1 class="text-center mt-5 text-l">EMBAJADORES</h1>
    <div class="row">
        <div class="col-sm box-embajador mt-5">
            <img src="assets/image/smiling-woman-with-a-shaker.png" 
                alt="Embajadora de productos Herbalife Soledad Oneto"
                class="img-fluid">
        </div>
        <div class="col-sm box-texto-embajador mt-5">
            <h1>SOLEDAD ONETTO</h1>
            <p>
                Embajadora de Herbalife Nutrition desde <br/>
                2013, es una de las periodistas <br/>
                más reconocida de nuestro país, siendo <br/>
                elegida “Rostro más creíble” en 2018. <br/>
                Animó el Festival de Viña del Mar los <br/>
                años 2009 y 2010. También ha sido parte <br/>
                de los eventos más importantes de la <br/>
                marca y siempre lleva consigo sus <br/>
                productos, siendo el Collagen Drink y la <br/>
                línea de cremas SKIN unos de sus <br/>
                favoritos.
            </p>
        </div>
    </div>
    <div class="box-embajador-gris mt-5">
        <div class="row">
            <div class="col-sm">
                <h1>IGNACIO CASALE</h1>
                <p>
                    Embajador de marca desde 2013, ha <br/>
                    ganado tres veces el Rally Dakar, el más <br/>
                    importante del mundo, los años 2014, <br/>
                    2018 y 2020 en la categoría Quads. Es un <br/>
                    rostro reconocido para toda la gran <br/>
                    familia Herbalife Nutrition, ya que ha <br/>
                    participado en los eventos más <br/>
                    importantes que ha organizado la <br/>
                    compañía traspasando su testimonio de <br/>
                    esfuerzo y sacrificio que lo caracterizan <br/>
                    como deportista de elite.<br/>
                    En todas sus competencias no puede <br/>
                    faltar la línea 24 de nutrición deportiva,<br/> 
                    que lo acompaña siempre.<br/>
                </p>
            </div>
            <div class="col-sm mr-5">    
                <img src="assets/image/happy-man-motorcycle.png" 
                    alt="IGNACIO CASALE embajador de productos Herbalife"
                    class="img-fluid">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm box-embajador mt-5">
            <img src="assets/image/two-men-posing.png" 
                alt="Embajadores de productos Herbalife MARCOS Y ESTEBAN GRIMALT"
                class="img-fluid">
        </div>
        <div class="col-sm box-texto-embajador">
            <h1>MARCOS Y ESTEBAN GRIMALT</h1>
            <p>
                Los primos se unieron al Team Herbalife 
                Nutrition a partir del 2019. Es la mejor <br/>
                dupla nacional de Beach Volley desde <br/>
                hace más de diez años, son una de las <br/>
                20 mejores parejas en la actualidad y <br/>
                fueron medalla de oro en los Juegos <br/>
                Panamericanos de Lima 2019. Ahora, <br/>
                están buscando la clasificación a Tokio <br/>
                2020, para disputar sus segundos <br/>
                Juegos Olímpicos luego de Río 2016. <br/>
                Rápidamente han incluido los productos <br/>
                de la marca en su nutrición diaria, no solo <br/>
                de la línea deportiva, sino también <br/>
                batidos, proteínas y multivitamínicos.
            </p>
        </div>
    </div>
</div>
