import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cremas',
  templateUrl: './cremas.component.html',
  styleUrls: ['./cremas.component.css']
})
export class CremasComponent implements OnInit {

  skin765: string;
  skin766: string;
  skin767: string;
  skin768: string;
  skin770: string;
  skin771: string;
  skin772: string;
  skin773: string;
  skin774: string;
  skin899: string;
  skin3293: string;
  skin3294: string;
  skin3295: string;
  herbalAloe: string;


  constructor() {
    this.skin765 = '22.700';
    this.skin766 = '22.700';
    this.skin767 = '17.200';
    this.skin768 = '51.200';
    this.skin770 = '35.800';
    this.skin771 = '35.800';
    this.skin772 = '17.800';
    this.skin773 = '19.300';
    this.skin774 = '38.500';
    this.skin899 = '38.500';
    this.skin3293 = '78.400';
    this.skin3294 = '78.400';
    this.skin3295 = '129.600';
    this.herbalAloe = '11.500';
  }

  ngOnInit(): void {
  }

}
