<div class="container mt-3">
    <h1 class="text-center articulo-title">Nutrición Favorita</h1>
</div>
<div class="bg-container" style="background-image: URL(/assets/image/FondoMarmol.png) ">
    <section id="recipie" class="main-container">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h1>RECETARIO</h1>
                    <p>Encuentra las mejores recetas creadas especialmente para ti</p>
                    <iframe src="https://e.issuu.com/embed.html?backgroundColor=%23ffffff&backgroundColorFullscreen=&d=libroderecetas-tomo2-issuu&u=herbalifelatino" 
                        width="100%" height="600" frameborder="0" allowfullscreen="true">
                    </iframe>
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <a href="/assets/pdf/Mi-Nutricion-Favorita.pdf">
                        <i class="btn btn-outline-secondary" 
                            aria-hidden="true">
                            Descarga el Tomo I
                        </i> 
                    </a>
                </div>
            </div>
            <div class="row">
                <hr/>
            </div>
            <div class="row">
                <div class="col-sm">
                    <a href="assets/pdf/Mi-Nutricion-Favorita_Tomo-II.pdf">
                        <img src="assets/image/libro-tomo-II.png" 
                            alt="Avatar"
                            width="400">
                                <i class="btn btn-outline-secondary ml-2"
                                aria-hidden="true">
                                Descarga el Tomo II
                            </i>
                    </a>
                </div>
            </div>
            <div class="row"></div>
        </div>
    </section>
</div>

