<div class="container mt-3">
    <h1 class="text-center articulo-title">RECETAS</h1>
</div>
<div class="container mt3">
    <div class="row">
        <div class="col-sm box-recetas">
            <div class="box-receta-title">
                <a [routerLink]="['/detalleWaffles']">WAFFLES REBUILD</a>
            </div>
            <img src="assets/image/waffles_rebuild.png" 
                alt="Waffles Rebuild"
                class="img-fluid"
            />
            <p>
                Prémiate con esta rica colación de waffles de 
                chocolate y disfruta sin remordimiento después de 
                hacer tu rutina de ejercicios.
            </p>
            <a [routerLink]="['/detalleWaffles']">Más detalles</a>
        </div>
        
        <div class="col-sm box-recetas">
            <div class="box-receta-title">
                <a [routerLink]="['/detalleCremaZapallo']">CREMA DE ZAPALLO</a>
            </div>
            <img src="assets/image/crema_de_zapallo.png" 
                alt="Crema de Zapallo"
                class="img-fluid"
            />
            <p>
                Fácil y rica receta de crema de zapallo ideal para 
                preparar en invierno.
            </p>
            <a [routerLink]="['/detalleCremaZapallo']">Más detalles</a>
        </div>
    </div>
    <div class="row">
        <div class="col-sm box-recetas">
            <div class="box-receta-title">
                <a [routerLink]="['/detalleFrutosRojos']">BATIDO DE REBUILD Y FRUTOS DEL BOSQUE</a>
            </div>
            <img src="assets/image/rebuild_frutos_rojos.png" 
                alt="rebuild frutos rojos"
                class="img-fluid"
            />
            <p>
                Rápida receta de batido de chocolate rico en proteínas para 
                después de ejercitarse.
            </p>
            <a [routerLink]="['/detalleFrutosRojos']">Más detalles</a>
        </div>
        
        <div class="col-sm box-recetas">
            <div class="box-receta-title">
                <a [routerLink]="['/detalleAderezoMango']">ADEREZO DE MANGO</a>
            </div>
            <img src="assets/image/aderezo_mango.png" 
                alt="Aderezo Mango"
                class="img-fluid"
            />
            <p>
                Deliciosa receta súper fácil de preparar con aloe mango para 
                que sorprendas a tus invitados con un rico aperitivo.
            </p>
            <a [routerLink]="['/detalleAderezoMango']">Más detalles</a>
        </div>
    </div>
</div>
