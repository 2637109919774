<div class="container mt-3 text-center">
    <img src="assets/image/new-proteina.png" 
        alt="" class="img-fluid">
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm box-img">
            <img src="assets/image/estilo-de-vida.png" 
                alt="" class="img-fluid"
                style="width:600px"/>
        </div>
        <div class="col-sm box-texto">
            <h1 class="text-center">La mejor Nutrición</h1>
            <p>Ofrecemos productos basados en la ciencia y de una alta calidad, 
                que proporcionan los nutrientes que tu cuerpo necesita para 
                prosperar día tras día. La buena nutrición tiene el poder de ayudar 
                a las personas a hacer grandes cosas.
            </p>
            <p>Por supuesto, no todo depende de nuestros productos. Ellos van de la 
                mano con una alimentación saludable y actividad física regular. 
                También cuentan con el respaldo de los Distribuidores Independientes 
                Herbalife Nutrition; ellos te ayudarán a empezar con mejores opciones 
                nutricionales y te guiarán en el camino para lograr tus objetivos.
            </p>
            <a  class="btn-hbl btn btn-success btn-lg" 
                [routerLink]="['batidos']">Quiero Comprar
            </a>
        </div>
        <div class="col-sm box-img">
            <img src="assets/image/estilo-de-vida2.png" 
                alt="" class="img-fluid"
                style="width:590px"/>
        </div>
    </div>
    <div class="row">
        <div class="col-sm box-texto-productos">
            <h1 class="text-center">
                Los productos Herbalife
            </h1>
            <p>
                Los productos son formulados y elaborados con altos estándares 
                de fabricación y la Filosofía de Nutrición Global Herbalife 
                Nutrition ha sido desarrollada por expertos en el campo de la 
                nutrición.
            </p>
            <p>La gama de productos de Herbalife Nutrition ha sido diseñada 
                por expertos, para ayudarte a alcanzar tus objetivos de salud 
                y bienestar, sin importar lo simples o complejos que sean.
            </p>
            <p>
                Tanto si buscas alcanzar tu peso saludable ideal como si eres 
                un atleta serio y competitivo que se esfuerza por mejorar su 
                rendimiento, estamos aquí para ayudarte.
            </p>
            <p>
                Para fabricar productos de gran calidad se necesita elegir los 
                mejores ingredientes. Por ello, viajamos por todo el mundo para 
                hacer una selección rigurosa de ingredientes que proporcionen a 
                tu cuerpo los nutrientes que necesita. Además, trabajamos junto 
                a agricultores responsables y comprometidos con la excelencia.
            </p>
            <p>
                Da el primer paso hacia una vida mejor y más saludable. ¡Comienza 
                tu proceso hoy mismo!
            </p>
        </div>
        <div class="col-sm box-texto-acerca">
            <div class="text-center">
                <img src="assets/image/acerca-de-los-productos.png" 
                alt="Acerca de los productos Herbalife" 
                class="img-fluid">
            </div>
            <h1 class="text-center">Acerca de los productos</h1>   
            <p>
                Las fórmulas exclusivas de los productos Herbalife Nutrition 
                han sido creadas utilizando selectos ingredientes y siguiendo 
                los más altos estándares de fabricación, elaboradas en 
                instalaciones de avanzada, bajo rigurosos procesos.
            </p> 
        </div>
    </div>
</div>