import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-suplementos',
  templateUrl: './suplementos.component.html',
  styleUrls: ['./suplementos.component.css']
})
export class SuplementosComponent implements OnInit {

  herbalifeLine: string;
  multivitaminico: string;
  nrg: string;
  nutriSoup: string;
  xtraCal: string;
  collagen: string;

  constructor() {
    this.herbalifeLine = '35.500';
    this.multivitaminico = '14.500';
    this.nrg = '20.300';
    this.nutriSoup = '31.900';
    this.xtraCal = '14.800';
    this.collagen = '35.800';
  }

  ngOnInit(): void {
  }

}
