<div class="container mt-3">
    <h1 class="text-center articulo-title text-large">Articulos</h1>
</div>
<div class="container mt3">
    <div class="row">
        <div class="col-sm box-articulos">
            <div class="box-articulo-title">
                <h1>
                    <a href="http://www.facebook.com/sharer.php?u=http://www.mipesoideal.com/detalledieta/"
                        target="_blank">
                        <img src="assets/image/facebook.png" 
                            alt="Compartir en Facebook el articulo Dieta de Moda"
                            class="img-fluid compartir"
                            width="50"
                        />
                    </a>
                    <a [routerLink]="['/detalledieta']">
                        Dieta de Moda: <br/>
                        ¿Es el Ayuno Intermitente una buena
                        alternativa para Bajar de Peso?
                    </a>
                </h1>
                <span>Autor: Herbalife Nutrition</span>
                <img src="assets/image/woman-eating-a-salad.png" 
                    alt="woman eating a salad"
                    class="img-fluid"
                />
                <p>
                    Las dietas hacen que las personas se animen a bajar de peso 
                    sin considerar la salud, como en este caso la dieta del ayuno 
                    que es una alternativa más y se debe evaluar si hace bien para 
                    el organismo, ya que lo importante es nutrir nuestro organismo 
                    y mantenerlo saludable.

                </p>
            </div>
            <a [routerLink]="['/detalledieta']">Más detalles</a>
        </div>
        <div class="col-sm box-articulos">
            <div class="box-articulo-title">
                <h1>
                    <a href="http://www.facebook.com/sharer.php?u=http://www.mipesoideal.com/detalleejercicios/"
                        target="_blank">
                        <img src="assets/image/facebook.png" 
                            alt="Compartir en Facebook el articulo 5 mejores ejercicios"
                            class="img-fluid compartir"
                            width="50"
                        />
                    </a>
                    <a [routerLink]="['/detalleejercicios']">
                        Los 5 mejores ejercicios para hacer en la casa
                    </a>
                </h1>
            </div>
            <div class="space"></div>
            <span>Autor: Herbalife Nutrition</span>

            <img src="assets/image/girl-doing-push-ups.png" 
                alt="woman eating a salad"
                class="img-fluid"
            />
            <p>
                No importa cuánto espacio tengas en casa, puedes hacer 
                una rutina rápida de ejercicio de cuerpo completo en 
                cualquier momento para estirar y fortalecer tu musculatura. 
                Todo lo que necesitas es tu propio peso corporal y un equipo 
                mínimo…
            </p>
            <a [routerLink]="['/detalleejercicios']">Más detalles</a>

        </div>
    </div>
</div>