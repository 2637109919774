import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { CabeceraComponent } from './components/cabecera/cabecera.component';
import { MenuComponent } from './components/menu/menu.component';
import { PiePaginaComponent } from './components/pie-pagina/pie-pagina.component';
import { routing } from './app.routing';
import { ProductosComponent } from './components/productos/productos.component';
import { BatidosComponent } from './components/batidos/batidos.component';
import { ProteinasComponent } from './components/proteinas/proteinas.component';
import { SuplementosComponent } from './components/suplementos/suplementos.component';
import { FibraComponent } from './components/fibra/fibra.component';
import { CremasComponent } from './components/cremas/cremas.component';
import { Nutricion24Component } from './components/nutricion24/nutricion24.component';
import { EmbajadoresComponent } from './components/embajadores/embajadores.component';
import { DesayunoComponent } from './components/desayuno/desayuno.component';
import { DeportistaComponent } from './components/deportista/deportista.component';
import { FavoritaComponent } from './components/favorita/favorita.component';
import { NegocioComponent } from './components/negocio/negocio.component';
import { ClientevipComponent } from './components/clientevip/clientevip.component';
import { PielComponent } from './components/piel/piel.component';
import { ArticulosComponent } from './components/articulos/articulos.component';
import { RecetasComponent } from './components/recetas/recetas.component';
import { DistribuidorComponent } from './components/distribuidor/distribuidor.component';
import { DetalledietaComponent } from './components/articulos/detalledieta/detalledieta.component';
import { DetalleejerciciosComponent } from './components/articulos/detalleejercicios/detalleejercicios.component';
import { CancelComponent } from './components/cancel/cancel.component';
import { SuccessComponent } from './components/success/success.component';
import { TesComponent } from './components/tes/tes.component';
import { AloesComponent } from './components/aloes/aloes.component';
import { DetalleCremaZapalloComponent } from './components/recetas/detalle-crema-zapallo/detalle-crema-zapallo.component';
import { DetalleWafflesComponent } from './components/recetas/detalle-waffles/detalle-waffles.component';
import { DetalleFrutosRojosComponent } from './components/recetas/detalle-frutos-rojos/detalle-frutos-rojos.component';
import { DetalleAderezoMangoComponent } from './components/recetas/detalle-aderezo-mango/detalle-aderezo-mango.component';
import { FormasPagoComponent } from './components/formas-pago/formas-pago.component';
import { ErrorComponent } from './components/error/error.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CabeceraComponent,
    MenuComponent,
    PiePaginaComponent,
    ProductosComponent,
    BatidosComponent,
    ProteinasComponent,
    SuplementosComponent,
    FibraComponent,
    CremasComponent,
    Nutricion24Component,
    EmbajadoresComponent,
    DesayunoComponent,
    DeportistaComponent,
    FavoritaComponent,
    NegocioComponent,
    ClientevipComponent,
    PielComponent,
    ArticulosComponent,
    RecetasComponent,
    DistribuidorComponent,
    DetalledietaComponent,
    DetalleejerciciosComponent,
    CancelComponent,
    SuccessComponent,
    TesComponent,
    AloesComponent,
    DetalleCremaZapalloComponent,
    DetalleWafflesComponent,
    DetalleFrutosRojosComponent,
    DetalleAderezoMangoComponent,
    FormasPagoComponent,
    ErrorComponent,
  ],
  imports: [
    BrowserModule,
    routing
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
