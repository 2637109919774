<div class="container pt-5">
    <h1 class="text-center articulo-title">CONVIÉRTETE EN DISTRIBUIDOR INDEPENDIENTE</h1>
</div>
<div class="container">
    <div class="row">
        <div class="col-sm text-center">
            <h2 class="pt-5">APASIONADO Y CON UN PROPÓSITO</h2>
            <p>
                Únete a la comunidad impulsada por el propósito de Herbalife Nutrition. 
                Comparte tu pasión con millones de personas entusiastas en todo el mundo 
                y ayúdanos en nuestra misión de mejorar las vidas de los que nos rodean.<br/>

                Te beneficiarás de la formación y el apoyo de expertos, además de la confianza 
                de saber que todos nuestros productos han sido desarrollados por científicos 
                líderes en nutrición.<br/>

                Descubre todos los beneficios de la oportunidad de negocio de Herbalife Nutrition, 
                como nuestra garantía Gold Standard.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-sm box-comenzar">
            <p>
                Si deseas iniciar tu propio negocio independiente, contactanos
                al <i class="contacto"> Email </i>
                y te ofreceremos la atención personalizada que necesitas. <br/>
                Tambien puedes contactarnos por. <br/> 
                <img src="assets/image/telefono.png" 
                alt=""
                width="20"
                class="img-fluid"
            />
            <i class="blanco">56 9 9745 3504</i><br/>
            <img src="assets/image/whatsapp.png" 
                alt=""
                width="20"
                class="img-fluid"
            />
            <i class="blanco">+56 9 9745 3504</i>
            </p>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm box-garantia">
        <p class="text-center">
            <img src="assets/image/gold-standard-banner.png" 
                alt="Garantia del estándar de oro"
                class="img-fluid"
            />
        </p>
        <h1 class="text-center pt-5 pb-5">
            GARANTÍA DEL ESTÁNDAR DE ORO
        </h1>
        <h2 class="text-center pb-5">
            HERBALIFE NUTRITION PROPORCIONA EL ESTÁNDAR <br/>
            DE ORO EN PROTECCIÓN AL CONSUMIDOR COMO:
        </h2>
        <div class="row">
            <div class="col-sm-4 offset-sm-2">
                <img src="assets/image/greentick.png" 
                    alt="ticket verde" 
                    width="80"
                    class="img-ticket img-fluid"
                />
                <p>
                    Bajo costo para iniciar tu negocio independiente.
                </p>
            </div>
            <div class="col-sm-3 box-objetivos">
                <img src="assets/image/greentick.png" 
                    alt="ticket verde" 
                    width="80"
                    class="img-ticket img-fluid"
                />
                <p>
                    Garantía de reembolso.
                </p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-sm-4 offset-sm-2 box-objetivos">
                <img src="assets/image/greentick.png" 
                    alt="ticket verde" 
                    width="80"
                    class="img-ticket img-fluid"
                />
                <p>
                    Información por adelantado sobre la oportunidad de 
                    negocio independiente y aceptación por escrito.
                </p>
            </div>
            <div class="col-sm-4 box-objetivos">
                <img src="assets/image/greentick.png" 
                    alt="ticket verde" 
                    width="80"
                    class="img-ticket img-fluid"
                />
                <p>
                    Guías sólidas de afirmaciones sobre la oportunidad de 
                    negocio independiente y nuestros productos.
                </p>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm box-nutricion pt-5">
        <h1 class="text-center">
            NUTRICIÓN DE CALIDAD 
        </h1>
        <p class="offset-sm-3 col-sm-6 text-center pt-5 pb-5">
            Nos esforzamos por elaborar los mejores productos de nutrición 
            del mercado, con ingredientes de la más alta calidad, para 
            ayudar a nuestros clientes a alcanzar sus objetivos de 
            bienestar. La piedra angular de la reputación internacional 
            de Nutrición Herbalife es una línea de productos de calidad.
        </p>
    </div>
</div>
