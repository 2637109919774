import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detalle-frutos-rojos',
  templateUrl: './detalle-frutos-rojos.component.html',
  styleUrls: ['./detalle-frutos-rojos.component.css']
})
export class DetalleFrutosRojosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
