<div class="container mt-3">
    <h1 class="text-center">Concentrados de Aloe Vera</h1>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm box-aloe">
            <div class="box-aloe-header text-center">Concentrado de Aloe Vera Uva </div>
            <div class="box-aloe-body">
                <h5 class="box-aloe-title">Aloe Vera Uva 473 ml.</h5>
                <h6 class="box-aloe-subtitle">Código 1610 </h6>
                <p class="text-center">
                    <img src="assets/image/aloe_uva.png" 
                        alt="Concentrado de Aloe Vera Uva"
                        class="img-fluid"
                        style="width: 150px;"> 
                </p>
                <p class="text-center precio">${{ aloes }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>Bebida a base de sábila de alta calidad.</li>
                    <li>Libre de calorías.</li>
                    <li>Contribuye a una óptima hidratación, disfrútalo 
                        con agua o mézclalo con tu bebida favorita.
                    </li>
                    <li>Apoya al bienestar del tracto digestivo.</li>
                </ul>
                <!-- <div class="box-aloe-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="NLS6XSU7Z46WA">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>      
                </div> -->
            </div>
        </div>
        <div class="col-sm box-aloe">
            <div class="box-aloe-header text-center">Concentrado de Aloe Vera Mango </div>
            <div class="box-aloe-body">
                <h5 class="box-aloe-title">Aloe Vera Mango 473 ml.</h5>
                <h6 class="box-aloe-subtitle">Código 1065 </h6>
                <p class="text-center">
                    <img src="assets/image/aloe_mango.png" 
                        alt="Concentrado de Aloe Vera Mango"
                        class="img-fluid"
                        style="width: 150px;"> 
                </p>
                <p class="text-center precio">${{ aloes }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>Alimento de aloe vera líquido para preparar bebida instantánea libre de calorías.</li>
                    <li>Apoya la salud digestiva.</li>
                    <li>Contribuye al bienestar intestinal.</li>
                </ul>
                <!-- <div class="box-aloe-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="PR9KH7XJEDDT2">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>                        
                </div> -->
            </div>
        </div>
        <div class="col-sm box-aloe">
            <div class="box-aloe-header text-center">Concentrado de Aloe Vera Mandarina </div>
            <div class="box-aloe-body">
                <h5 class="box-aloe-title">Aloe Vera Mandarina 473 ml.</h5>
                <h6 class="box-aloe-subtitle">Código 2631 </h6>
                <p class="text-center">
                    <img src="assets/image/aloe_mandarina.png" 
                        alt="Concentrado de Aloe Vera Mandarina"
                        class="img-fluid"
                        style="width: 150px;"> 
                </p>
                <p class="text-center precio">${{ aloes }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>Alimento de aloe vera líquido para preparar bebida instantánea libre de calorías.</li>
                    <li>Apoya la salud digestiva.</li>
                    <li>Contribuye al bienestar intestinal.</li>
                </ul>
                <!-- <div class="box-aloe-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="2VU74RWD8JVRN">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</div>