<div class="container mt-3 text-center">
    <h1 class="text-center">Fibra Herbalife</h1>
    <div class="container">
        <div class="row">
            <div class="col-sm box-fibra">
                <div class="box-fibra-header text-center">Fibra Activa</div>
                <div class="box-fibra-body">
                    <h5 class="box-fibra-title">Fibra soluble e insoluble 210 g.</h5>
                    <h6 class="box-fibra-subtitle">Código 2864 </h6>
                    <p class="text-center"> 
                        <img src="assets/image/fibra_activa-manzana.png" 
                            alt="Fibra Activa"
                            style="width: 100px;"> 
                    </p>
                    <p class="precio text-center">${{ fibra }}.-</p>
                    <h5 class="text-center">Descripción general</h5>
                    <ul class="text-left">Beneficios:
                        <li>Alimento en polvo con base en fibra dietética.</li>
                        <li>Bajo en calorías.</li>
                        <li>Aporta fibra, una porción provee 5 g de los 25 g de la 
                            ingesta diaria recomendada de fibra soluble e insoluble.
                        </li>
                        <li>Puede contribuir diariamente al correcto funcionamiento intestinal.</li>
                        <li>Añada Fibra Activa a su Nutritional Shake Mix o mézclela con agua.</li>
                    </ul>
                    <!-- <div class="box-fibra-footer text-center">
                        <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                            <input type="hidden" name="cmd" value="_s-xclick">
                            <input type="hidden" name="hosted_button_id" value="Z88HR262M7MPA">
                            <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                            <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                        </form>
                    </div> -->
                </div>
            </div>
            <div class="col-sm box-fibra-image">
                <img src="assets/image/fibra-activa.png" 
                    alt="Fibra Activa de manzana"
                    width="800"
                    class="img-fluid"
                />
            </div>
        </div>
    </div>
</div>