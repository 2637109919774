<div class="container pt-5">
    <!-- <div class="row">
        <div class="col-sm">
            <h1 class="text-center pb-5">
                Formas de Pago
            </h1>
            <img src="assets/image/logo-paypal.png" 
                alt="logo paypal" 
                class="img-fluid img-pagos"
            />
        </div>
        <div class="col-sm">
            <ul style="padding-top: 160px;">
                <li class="text-l">Utilice los botones de pago en los productos que desea adquirir.</li>
                <li class="text-l">Pague con tarjeta de crédito a travez de Paypal</li>
                <li class="text-l">Le enviaremos su pedido al domicilio indicado</li>
            </ul>
        </div>
    </div> -->
    <div class="row">
        <div class="col-sm pt-5">
            <h1 class="text-center pb-5">
                Transferencias
            </h1>
            <img src="assets/image/bancoestado.png" 
                alt="logo bancoestado" 
                class="img-fluid img-pagos"
            />
        </div>
        <div class="col-sm">
            <ul style="padding-top: 160px; padding-bottom: 100px">
                <li class="text-l">Pongase en contacto con nosotros, por teléfono o whatsapp +56 9 9745 3504.</li>
                <li class="text-l">Le daremos los detalles para que haga una transferencia.</li>
                <li class="text-l">Haga la transferencia por el valor de los productos que necesita.</li>
                <li class="text-l">Enviaremos el pedido a su domicilio.</li> 
            </ul>
        </div>
    </div>
</div>