<div class="container mt-3 text-center">
    <h1 class="text-center">Nutricion Deportiva Herbalife</h1>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm box-nutricion24">
            <div class="box-nutricion24-header text-center">Sport Activator 90 cápsulas</div>
            <div class="box-nutricion24-body">
                <h5 class="box-nutricion24-title">Alimento para deportista.</h5>
                <h6 class="box-nutricion24-subtitle">Código 3123 </h6>
                <p class="image-h24 text-center"> 
                    <img src="assets/image/sport_activator.png" 
                        alt="Sport Activator"
                        style="width: 100px;"> 
                </p>
                <p class="precio text-center">${{ sportActivator }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>La L-Carnitina apoya la recuperación 
                        muscular asociada al entrenamiento.
                    </li>
                    <li>
                        Contiene vitaminas del Grupo B que 
                        ayudan a mantener un metabolismo energético 
                        normal y a reducir el cansancio y la fatiga.
                    </li>
                </ul>
                <!-- <div class="box-nutricion24-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="WT9WSFG92C5MA">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>

        <div class="col-sm box-nutricion24-image">
            <img src="assets/image/estilo-de-vida3.png" 
                alt="Fibra Activa de manzana"
                width="800"
                class="img-fluid"
            />
            <img src="assets/image/h241.png" 
                alt="Fibra Activa de manzana"
                width="800"
                class="img-fluid"
            />
            <img src="assets/image/h242.png" 
                alt="Fibra Activa de manzana"
                width="800"
                class="img-fluid"
            />
        </div>

        <div class="col-sm box-nutricion24">
            <div class="box-nutricion24-header text-center">CR7 Drive Acaí Frutos Rojos 810 g</div>
            <div class="box-nutricion24-body">
                <h5 class="box-nutricion24-title">CR7 DRIVE </h5>
                <h6 class="box-nutricion24-subtitle">Código 1478 </h6>
                <p class="text-center"> 
                    <img src="assets/image/cr7_drive.png" 
                        alt="CR7 DRIVE"
                        style="width: 100px;"
                    /> 
                </p>
                <p class="precio text-center">${{ cr7 }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>
                        Repone parte de los líquidos y electrolitos 
                        perdidos en el sudor durante el entrenamiento.
                    </li>
                    <li>
                        Mezcla de carbohidratos que ayudan a mejorar la resistencia.
                    </li>
                    <li>
                        Contiene glucosa para obtener energía rápidamente y fructosaque 
                        puede ser utilizada como fuente secundaria de energía durante 
                        el ejercicio.
                    </li>
                    <li>
                        Contiene magnesio, que contribuye a mejorar el rendimiento físico.
                    </li>
                </ul>
                <!-- <div class="box-nutricion24-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="6GQH9XFVDM9AY">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>

        <div class="col-sm box-nutricion24">
            <div class="box-nutricion24-header text-center">Herbalife24 Rebuild Strength Chocolate 1000 g</div>
            <div class="box-nutricion24-body">
                <h5 class="box-nutricion24-title"></h5>
                <h6 class="box-nutricion24-subtitle">Código 1417 </h6>
                <p class="text-center"> 
                    <img src="assets/image/reubuild.png" 
                        alt="H24 - Rebuild Strength"
                        style="width: 100px;"
                    /> 
                </p>
                <p class="precio text-center">${{ herbalife24 }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>
                        Apoyo:los aminoácidos incluidos naturalmente 
                        en la proteína láctea ayudan al crecimiento muscular.
                    </li>
                    <li>
                        Nutrición:promueve la formación de proteína, gracias a sus 
                        20g de proteínas de suero de leche y de caseína, que proveen 
                        aminoácidos en forma rápida y sostenida.
                    </li>
                    <li>
                        Protección:contiene hierro como elemento esencial para la 
                        producción de glóbulos rojos.
                    </li>
                    <li>
                        Restitución:ayuda a reponer el glucógeno muscular.
                    </li>
                    <li>
                        Sabor: Chocolate
                    </li>
                </ul>
                <!-- <div class="box-nutricion24-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="FRMKDKANQ8TFG">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</div>