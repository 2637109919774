import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detalledieta',
  templateUrl: './detalledieta.component.html',
  styleUrls: ['./detalledieta.component.css']
})
export class DetalledietaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
