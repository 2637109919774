import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-favorita',
  templateUrl: './favorita.component.html',
  styleUrls: ['./favorita.component.css']
})
export class FavoritaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
