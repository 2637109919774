<div class="container">
    <div class="alert alert-success text-center">
        <h1 class="verde txt-l">
            Su Operación, de Pago se ha efectuado éxitosamente
            Pronto le enviaremos su pedido
        </h1>
    </div>
    <hr/>
    
    <hr/>

    <p class = "text-m text-center">Gracias por su prefencia</p>
    <p class = "text-m text-center">Tenga un cordial saludo</p>
    <hr/>
</div>
