<div class="container">
    <div class="row">
        <div class="col-sm box-articulo-detalle">
            <div class="box-articulo-title-detalle">
                <h1>ADEREZO DE MANGO</h1>
            </div>
            <p class="text-center">
                <img src="assets/image/aderezo_mango.png" 
                    alt="Aderezo Mango"
                    class="img-fluid"
                />
            </p>
            <i>
                Porciones: 16<br/>
                Tiempo de preparación: 10 minutos<br/>
            </i>
            <br>
            <h5>Ingredientes</h5>
            <ul>
                <li>2 cucharadas semillas de chía</li>
                <li>2 cucharadas fibra activa</li>
                <li>1/3 taza agua</li>
                <li>2 tapas concentrado de aloe vera sabor mango</li>
                <li>1/4 taza jugo de limón</li>
                <li>1/4 taza aceite de oliva</li>
                <li>1 cucharada mostaza dijon</li>
                <li>1 cucharadita sal</li>
                <li>1/2 cucharadita pimienta</li>
                <li>1/2 cucharadita orégano seco</li>
            </ul>
            <h5>Método de preparación:</h5>
            <p></p>
            <h5>PREPARACIÓN</h5>
            <p>
                Mezcla las semillas de chía, fibra activa y el agua. 
                Se deja reposar 3 minutos aproximadamente, hasta que la 
                mezcla espese. Luego agrega el resto de los ingredientes 
                y se deja en una botella o jarro.
            </p>
            <h5>Nutrientes por porción</h5>
            <table class="table">
                <th></th>
                <th>Calorías (kcal)</th>
                <th>Grasas (g)</th>
                <th>Carbohidratos (g)</th>
                <th>Proteína (g)</th>
                <tr></tr>
                <td>Por porción</td>
                <td>50 cal.</td>
                <td>5 g.</td>
                <td>1,5 g.</td>
                <td>0,5 g.</td>
            </table>
            <hr>
            <i>Si lo mantienes en el refrigerador tapado, 
                puede durar hasta una semana.
            </i>
        </div>
    </div>
</div>
