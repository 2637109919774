<div class="container">
    <div class="row">
        <div class="col-sm box-articulo-detalle">
            <div class="box-articulo-title-detalle">
                <h1>
                    Los 5 mejores ejercicios para hacer en la casa 
                </h1>
                <span>Autor: Herbalife Nutrition</span>
                <p class="text-center">
                    <img src="assets/image/girl-doing-push-ups.png" 
                        alt="woman eating a salad"
                        class="img-fluid"
                    />
                </p>
            </div>
            <p>
                La distancia social y las cuarentenas por las que estamos 
                atravesando, no pueden ser un impedimento para mantenernos 
                activos, más aún cuando el deporte nos entrega una serie de 
                beneficios. Según Samantha Clayton, Vicepresidenta de Rendimiento 
                Deportivo y Educación Física Mundial de Herbalife Nutrition, 
                cuando haces ejercicio, mejoras la liberación natural de 
                endorfinas en tu cuerpo. Este aumento de la hormona de la 
                felicidad puede mejorar tu estado de ánimo y tu confianza, y la 
                buena noticia es que puedes hacer ejercicio en cualquier lugar, 
                incluso en casa.
            </p>
            <p>
                El “estrés de ejercicio”, cuando se maneja bien, es un 
                estrés saludable que con el tiempo empuja a tu cuerpo a 
                adaptarse y volverse más fuerte y eficiente. Algunos 
                estudios sugieren que hacer ejercicio de forma regular es 
                beneficioso para la <a href="https://yosoyherbalifenutrition.com/salud-y-bienestar/sistema-inmunologico-nutricion/"
                 target="_blank">salud inmunológica </a>, ya que puede tener un efecto positivo en la 
                capacidad de tu cuerpo para mantenerse bien y combatir enfermedades comunes.
            </p>
            <p>
                Otros estudios, dice Clayton, han encontrado que, durante 
                la temporada de resfríos, el aumento temporal de la 
                temperatura corporal puede desalentar el crecimiento de 
                ciertas bacterias y los beneficios del estrés del ejercicio 
                ayudan a mantenerte sintiéndote en tu mejor punto.
            </p>
            <h2>5 EJERCICIOS PARA HACER EN CASA</h2>
            <p>
                “No importa cuánto espacio tengas en casa, puedes hacer una rutina 
                rápida de ejercicio de cuerpo completo en cualquier momento para 
                estirar y fortalecer tu musculatura. Todo lo que necesitas es tu 
                propio peso corporal y un equipo mínimo”, afirma la Vicepresidenta 
                de Rendimiento Deportivo y Educación Física Mundial de Herbalife Nutrition. 
                Clayton aquí comparte una guía de ejercicios para practicar en la comodidad 
                de tu hogar:
            </p>
            <p>
                Repeticiones: Realiza 10-12 de cada ejercicio. Repite los ejercicios 4 veces 
                para una rutina completa.
            </p>
            <p>Tiempo total de la rutina: 20 minutos.</p>
            <h2>1. FLEXIONES DE TRÍCEPS</h2>
            <p>Este ejercicio trabaja la parte posterior de los brazos y los hombros:</p>
            <p>Siéntate en el piso con las rodillas ligeramente dobladas.</p>
            <p>Coloca las manos detrás de ti con los dedos mirando hacia tu cuerpo.</p>
            <p>Levanta el trasero del piso para que tus brazos y pies lo sostengan.</p>
            <p>Dobla los brazos por el codo hasta que tu trasero toque el piso y 
                luego vuelve a subir a la posición inicial.
            </p>
            <p>
                Si deseas un desafío adicional mientras empujas hacia arriba, levanta la 
                pierna izquierda y extiende hacia adelante con el brazo derecho.
            </p>
            <h2>2. LAGARTIJAS</h2>
            <p>
                Este es un ejercicio de cuerpo completo, ya que requiere el uso de muchos grupos 
                musculares:
            </p>
            <p>
                Acuéstate boca abajo en el piso y coloca las manos con las palmas hacia abajo 
                en el piso, aproximadamente al ancho de los hombros y cerca de los hombros.
            </p>
            <p>
                Las puntas de los pies deben tocar el suelo y los pies ligeramente separados.
            </p>
            <p>
                Levántate con los brazos.
            </p>
            <p>
                Haz una línea recta desde la cabeza hasta los talones y contrae los abdominales 
                para evitar que las caderas se hundan. Esta posición es el comienzo y la posición 
                final de una sola lagartija. Baja el pecho hacia el piso doblando los codos por 
                un segundo y luego regresa al inicio.
            </p>
            <h2>3. BALANCE DE MANOS Y RODILLAS CON ABDOMINAL</h2>
            <p>
                Este ejercicio trabaja tu equilibrio y músculos abdominales:<br/><br/>
                Ponte en el piso en cuatro patas. Manos directamente debajo de los hombros, 
                rodillas debajo de las caderas. Mantén la espalda plana.<br/><br/>
                Levanta el brazo derecho hacia adelante y la pierna izquierda hacia atrás.<br/><br/>
                Lleva la rodilla hacia el pecho al mismo tiempo que acerca el codo para alcanzar 
                la rodilla.<br/><br/>
                Haz esto 10 veces y luego cambia de pierna.
            </p>
            <h2>4. SENTADILLA</h2>
            <p>
                Este es un ejercicio funcional que trabaja el grupo muscular más grande del cuerpo: 
                el trasero y las piernas:<br/><br/>
                Párate con los pies un poco más separados que el ancho de los hombros, las caderas 
                sobre las rodillas y las rodillas sobre los tobillos.<br/><br/>
                Extiende los brazos rectos de manera que queden paralelos al suelo, con las palmas 
                hacia abajo.<br/><br/>
                Comienza por ponerte en una posición como si fueras a sentarte en una silla. 
                Mientras el trasero comienza a sobresalir, asegúrate de que el pecho y los hombros 
                se mantengan erguidos y que la espalda permanezca recta. Mantén la cabeza hacia 
                adelante con los ojos al frente para que tu columna esté neutral.<br/><br/>
                Las mejores sentadillas son las más profundas que te permita tu movilidad. 
                La profundidad óptima de la sentadilla sería que tus caderas se hundieran debajo de 
                las rodillas.<br/><br/>
                Endurece tu abdomen y, con el peso corporal en los talones, empuja hacia atrás para 
                ponerte de pie.
            </p>
            <h2>5. DESPLANTES CON ALZAMIENTO DE RODILLA</h2>
            <p>
                Este ejercicio trabaja la parte delantera y trasera de las piernas:<br/><br/>
                Con el pecho levantado, la barbilla levantada y los abdominales contraídos, da un 
                gran paso hacia atrás con el pie izquierdo.<br/><br/>
                Húndete hacia abajo para que tu rodilla trasera apunte hacia el piso. Tu pie 
                delantero está firme en el piso, mientras empujas hacia atrás a la posición inicial, 
                levanta la rodilla frente a ti y sostén por un segundo, luego repite y cambia de pierna.<br/><br/>
                “Apegarte a tu rutina de ejercicios, consumir una dieta saludable y descansar lo 
                suficiente son solo algunas de las excelentes maneras en que puedes esforzarte por 
                obtener los mejores resultados físicos y emocionales”.<br/><br/>
                Si estás interesado en realizar más rutinas de ejercicios para hacer desde tu casa, 
                la compañía global de nutrición Herbalife Nutrition ofrece un portal gratuito de 
                ejercicios al que puedes acceder en <a href="https://herbalifenutritionfitness.com/"
                target="_blank">https://herbalifenutritionfitness.com/</a>
                <a href="http://www.facebook.com/sharer.php?u=http://www.mipesoideal.com/detalleejercicios/"
                        target="_blank">
                        <img src="assets/image/facebook.png" 
                            alt="Compartir en Facebook el articulo 5 mejores ejercicios"
                            class="img-fluid compartir"
                            width="50"
                        />
                    </a> 
            </p>
        </div>
    </div>
</div>