import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nutricion24',
  templateUrl: './nutricion24.component.html',
  styleUrls: ['./nutricion24.component.css']
})
export class Nutricion24Component implements OnInit {

  sportActivator: string;
  cr7: string;
  herbalife24: string;

  constructor() {
    this.sportActivator = '30.800';
    this.cr7 = '31.200';
    this.herbalife24 = '65.000';
  }

  ngOnInit(): void {
  }

}
