import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tes',
  templateUrl: './tes.component.html',
  styleUrls: ['./tes.component.css']
})
export class TesComponent implements OnInit {

  tes: string;
  teHierba: string;

  constructor() {
    this.tes = '25.500';
    this.teHierba = '44.700';
  }

  ngOnInit(): void {
  }

}
