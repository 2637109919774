<h1 class="text-center">Suplementos Herbalife</h1>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm box-suplemento">
            <div class="box-suplemento-header text-center">Herbalife Line</div>
            <div class="box-suplemento-body">
                <h5 class="box-suplemento-title">Capsulas de Omega-3 71.1 g.</h5>
                <h6 class="box-suplemento-subtitle">Código 0065 </h6>
                <p class="text-center"> 
                    <img src="assets/image/herbalife-line.png" 
                        alt="Capsulas de omega 3"
                        style="width: 100px;"> 
                </p>
                <p class="precio text-center">${{ herbalifeLine }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul>Beneficios:
                    <li>Esta exclusiva mezcla de lípidos marinos altamente refinados contiene ácidos grasos Omega-3 de alta calidad.</li>
                    <li>Incluye vitamina E, que apoya la protección contra los radicales libres.</li>
                    <li>En prácticas cápsulas blandas.</li>
                </ul>
                <!-- <div class="box-suplemento-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="MG6BJWYR8P4XA">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>
        <div class="col-sm box-suplemento">
            <div class="box-suplemento-header text-center">Multivitaminico y minerales </div>
            <div class="box-suplemento-body">
                <h5 class="box-suplemento-title">Complejo Multivitamínico 109 g.</h5>
                <h6 class="box-suplemento-subtitle">Código 3122 </h6>
                <p class="text-center"> 
                    <img src="assets/image/multivitaminico.png" 
                        alt="suplemento de vitamnas e"
                        style="width: 100px;"> 
                </p>
                <p class="precio text-center">${{ multivitaminico }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul>Beneficios:
                    <li>Suplemento alimentario de vitamina A, niacina, folato, biotina y 9 minerales.</li>
                    <li>Fortificado con calcio y 8 vitaminas. Con mezcla de frutas en polvo.</li>
                    <li>Inclúyalo como parte de su alimentación diaria para una buena nutrición.</li>
                </ul>
                <!-- <div class="box-suplemento-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="5AQGRXL7JET4Y">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>
        <div class="col-sm box-suplemento">
            <div class="box-suplemento-header text-center">NRG Mezcla de Té Original </div>
            <div class="box-suplemento-body">
                <h5 class="box-suplemento-title">Producto en polvo para preparar té instantáneo 60 g.</h5>
                <h6 class="box-suplemento-subtitle">Código 0102 </h6>
                <p class="text-center"> 
                    <img src="assets/image/te-n-r-g.png" 
                        alt="Producto en polvo para preparar té instantáneo"
                        style="width: 90px;"> 
                </p>
                <p class="precio text-center">${{ nrg }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul>Beneficios:
                    <li>Alimento con base en té naranja pekoe, guaraná, limón y té verde.</li>
                    <li>El ingrediente activo de la guaraná es la cafeína.</li>
                    <li>Excelente refrigerio.</li>
                    <li>Puede tomarse frío o caliente.</li>
                </ul>
                <!-- <div class="box-suplemento-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="Y83HADG94RRQ2">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm box-suplemento">
            <div class="box-suplemento-header text-center">Nutri Soup </div>
            <div class="box-suplemento-body">
                <h5 class="box-suplemento-title">Pollo con vegetales 416 gs.</h5>
                <h6 class="box-suplemento-subtitle">Código 0748 </h6>
                <p class="text-center"> 
                    <img src="assets/image/nutrisoup.png" 
                        alt="suplemento Pollo con vegetales"
                        style="width: 150px;"> 
                </p>
                <p class="precio text-center">${{ nutriSoup }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul>Beneficios:
                    <li>Ayuda a reducir las calorías consumidas sin reducir el aporte de nutrientes esenciales</li>
                    <li>Buena fuente de proteína</li>
                    <li>Buena fuente de fibra dietética</li>
                    <li>Contiene 21 vitaminas y minerales</li>
                    <li>Bajo en grasas</li>
                    <li>Sabor: Pollo con verdura</li>
                </ul>
                <!-- <div class="box-suplemento-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="2KEZQJP6CS6E4">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>
        <div class="col-sm box-suplemento">
            <div class="box-suplemento-header text-center">XTRA-CAL </div>
            <div class="box-suplemento-body">
                <h5 class="box-suplemento-title">Xtra-Cal Advanced 126.8 g.</h5>
                <h6 class="box-suplemento-subtitle">Código 0020 </h6>
                <p class="text-center"> 
                    <img src="assets/image/xtra_cal.png" 
                        alt="Xtra-Cal Advanced"
                        style="width: 100px;"> 
                </p>
                <p class="precio text-center">${{ xtraCal }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul>Beneficios:
                    <li>Tabletas suplementadas con calcio, magnesio, zinc y cobre.</li>
                    <li>Fortificada con vitamina D.</li>
                    <li>La ingesta diaria de calcio y vitamina D puede apoyar la salud ósea.</li>
                </ul>
                <!-- <div class="box-suplemento-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="EUHH3XCG69NW4">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>
        <div class="col-sm box-suplemento">
            <div class="box-suplemento-header text-center">Collagen Drink </div>
            <div class="box-suplemento-body">
                <h5 class="box-suplemento-title">Limonada de Fresa 171 g</h5>
                <h6 class="box-suplemento-subtitle">Código 3987 </h6>
                <p class="text-center"> 
                    <img src="assets/image/collagen_drink.png" 
                        alt="Collagen Drink Limonada de Fresa"
                        width="170"
                    /> 
                </p>
                <p class="precio text-center">${{ collagen }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul>Beneficios:
                    <li>
                        Contiene péptidos de colágeno Verisol de alta calidad, que 
                        contribuye con la normal producción de colágeno y elastina 
                        en el cuerpo
                    </li>
                    <li>
                        Provee nutrientes antioxidantes como la vitamina C y E, que 
                        protegen el cuerpo de los dañinos radicales libres que promueven 
                        el proceso de envejecimiento celular
                    </li>
                    <li>
                        Contiene cobre, el cual contribuye con una normal pigmentación 
                        de la piel y cabellos
                    </li>
                    <li>Contiene selenio, zinc, biotina</li>
                    <li>
                        Contiene vitaminas y minerales que contribuyen al cuidado de piel, 
                        cabello y uñas
                    </li>
                    <li>Sabor natural</li>
                    <li>Certificado GFCO, libre de gluten</li>
                </ul>
                <!-- <div class="box-suplemento-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="PAF5MPLWJTKV4">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</div>


