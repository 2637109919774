<div class="container mt-3">
    <h1 class="text-center">Batidos Nutricionales</h1>
</div>
<div class="col-sm-12">
    <ul class="texto-mayusculas list-inline">
        <li class="list-inline-item"><a href=""> control de peso  </a></li> |
        <li class="list-inline-item"><a href=""> desayuno saludable  </a></li> |
        <li class="list-inline-item"><a href=""> proteinas  </a></li> |
        <li class="list-inline-item"><a href=""> hidratación, potenciadores y energéticos  </a></li> |
        <li class="list-inline-item"><a href=""> complementos alimenticios y productos específicos  </a></li> |
        <li class="list-inline-item"><a href=""> nutrición deportiva  </a></li> |
        <li class="list-inline-item">
            <a [routerLink]="['/productos']">todos los productos</a>
        </li> |
    </ul>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm box-batido">
            <div class="box-batido-header text-center">Batido Nutricional </div>
            <div class="box-batido-body">
                <h5 class="box-batido-title">Banana Caramelo 550 gs.</h5>
                <h6 class="box-batido-subtitle">Código 1522 </h6>
                <p class="text-center">
                    <img src="assets/image/banana_caramelo.png" 
                        alt="Batido nutricional Banana Caramelo"
                        class="img-fluid"
                        style="width: 150px;"> 
                </p>
                <p class="text-center precio">${{ batidos }}.-</p>
                <!-- <div class="box-batido-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="K39FC6TPL35K6">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>
        <div class="col-sm box-batido">
            <div class="box-batido-header text-center">Batido Nutricional </div>
            <div class="box-batido-body">
                <h5 class="box-batido-title">Choco Avellanas 550 gs.</h5>
                <h6 class="box-batido-subtitle">Código 3144 </h6>
                <p class="box-batido-img text-center"> 
                    <img src="assets/image/choco_avellana.png" 
                        alt="Batido nutricional Chocolate Avellana"
                        class="img-fluid"
                        style="width: 150px;"> 
                </p>
                <p class="text-center precio">${{ batidos }}.-</p>
                <!-- <div class="box-batido-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="WLD9XKZLY6QC6">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                        
                </div> -->
            </div>
        </div>
        <div class="col-sm box-batido">
            <div class="box-batido-header text-center">Batido Nutricional </div>
            <div class="box-batido-body">
                <h5 class="box-batido-title">Canela y Especias 550 gs.</h5>
                <h6 class="box-batido-subtitle">Código 2638 </h6>
                <p class="box-batido-img text-center"> 
                    <img src="assets/image/canela.png" 
                        alt="Batido nutricional Canela y Especias"
                        class="img-fluid"
                        style="width: 150px;"> 
                </p>
                <p class="text-center precio">${{ batidos }}.-</p>
                <!-- <div class="box-batido-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="NYE8JLEDHQNHA">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                        
                </div> -->
            </div>
        </div>
        <div class="col-sm box-batido">
            <div class="box-batido-header text-center">Batido Nutricional </div>
            <div class="box-batido-body">
                <h5 class="box-batido-title">Dulce de Leche 550 gs.</h5>
                <h6 class="box-batido-subtitle">Código 0940 </h6>
                <p class="box-batido-img text-center"> 
                    <img src="assets/image/dulce_de_leche.png" 
                        alt="Batido nutricional Dulce de Leche"
                        class="img-fluid"
                        style="width: 150px;"> 
                </p>
                <p class="text-center precio">${{ batidos }}.-</p>
                <!-- <div class="box-batido-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="G2JN47VN89HA4">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                        
                </div> -->
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm box-batido">
            <div class="box-batido-header text-center">Batido Nutricional </div>
            <div class="box-batido-body">
                <h5 class="box-batido-title">Frutilla 550 gs.</h5>
                <h6 class="box-batido-subtitle">Código 0143 </h6>
                <p class="text-center"> 
                    <img src="assets/image/frutilla.png" 
                        alt="Batido nutricional Frutilla"
                        class="img-fluid"
                        style="width: 150px;"> 
                </p>
                <p class="text-center precio">${{ batidos }}.-</p>
                <!-- <div class="box-batido-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="E8RQH9YHDFY26">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                        
                </div> -->
            </div>
        </div>
        <div class="col-sm box-batido">
            <div class="box-batido-header text-center">Batido Nutricional </div>
            <div class="box-batido-body">
                <h5 class="box-batido-title">Naranja Crema 550 gs.</h5>
                <h6 class="box-batido-subtitle">Código 1134 </h6>
                <p class="box-batido-img text-center"> 
                    <img src="assets/image/naranja_crema.png" 
                        alt="Batido nutricional Naranja Crema"
                        class="img-fluid"
                        style="width: 150px;"> 
                </p>
                <p class="text-center precio">${{ batidos }}.-</p>
                <!-- <div class="box-batido-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="2AY7H8YGCUYAJ">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>    
                </div> -->
            </div>
        </div>
        <div class="col-sm box-batido">
            <div class="box-batido-header text-center">Batido Nutricional </div>
            <div class="box-batido-body">
                <h5 class="box-batido-title">Chocolate 550 gs.</h5>
                <h6 class="box-batido-subtitle">Código 0142 </h6>
                <p class="box-batido-img text-center"> 
                    <img src="assets/image/chocolate.png" 
                        alt="Batido nutricional Chocolate"
                        class="img-fluid"
                        style="width: 150px;"> 
                </p>
                <p class="text-center precio">${{ batidos }}.-</p>
                <!-- <div class="box-batido-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="WTTVWL6YAPPMG">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>
        <div class="col-sm box-batido">
            <div class="box-batido-header text-center">Batido Nutricional </div>
            <div class="box-batido-body">
                <h5 class="box-batido-title">Cookies & Cream 550 gs.</h5>
                <h6 class="box-batido-subtitle">Código 0146 </h6>
                <p class="box-batido-img text-center"> 
                    <img src="assets/image/cookies_&_cream.png" 
                        alt="Batido nutricional Cookies & Cream"
                        class="img-fluid"
                        style="width: 150px;"> 
                </p>
                <p class="text-center precio">${{ batidos }}.-</p>
                <!-- <div class="box-batido-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="22BH6FJCV33S8">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                        
                </div> -->
            </div>
        </div>
    </div>
    
</div>