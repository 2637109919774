<div class="container mt-3 text-center">
    <h1 class="text-center">NUTRE TU PIEL</h1>
</div>
<div class="box-piel">
    <div class="row">
        <div class="col-sm">
            <h2>
                La piel es el órgano más extenso, necesita y merece mucha atención 
                y cariño

            </h2>
            <p>
                Cuidar de la piel adecuadamente debe ser parte de tu rutina 
                diaria, siete días a la semana, 52 semanas al año. De esa 
                manera, no importa lo que la naturaleza te depare: sol, 
                viento o contaminación; tu piel estará protegida. 
            </p>
        </div>
    </div>
</div>
<div class="container">
    <div class="row pl-5">
        <div class="sm">
            <div class="box-img-piel">
                <img src="assets/image/nutre_tu_piel.png" 
                    alt="Nutre tu piel"
                    class="img-fluid"
                    width="500"
                />
            </div>
        </div>
        <div class="sm">
            <div class="box-img-piel">
                <img src="assets/image/herbal_aloe.png" 
                    alt="Herbal Aloe"
                    class="img-fluid"
                    width="500"
                />
            </div>
        </div>
    </div>
</div>





