<div class="container box-productos-title mt-3 text-center">
    <h1>NUESTROS PRODUCTOS</h1>

    <div class="col-sm">
        <div class="cmp-text showMore" id="showmore">
            <p>La gama de productos de Herbalife Nutrition ha sido 
                diseñada por expertos, para ayudarte a alcanzar tus 
                objetivos de salud y bienestar, sin importar lo simples 
                o complejos que sean. Tanto si buscas alcanzar tu peso 
                saludable ideal como si eres un atleta serio y competitivo 
                que se esfuerza por mejorar su rendimiento, estamos aquí 
                para ayudarte.<br />
                Para fabricar productos de gran calidad se necesita elegir 
                los mejores ingredientes. Por ello, viajamos por todo el 
                mundo para hacer una selección rigurosa de ingredientes 
                que proporcionen a tu cuerpo los nutrientes que necesita. 
                Además, trabajamos junto a agricultores responsables y 
                comprometidos con la excelencia.<br />
                Da el primer paso hacia una vida mejor y más saludable. 
                ¡Comienza tu proceso hoy mismo!
            </p>
        </div>
    </div>
</div>
<div class="container box-productos-title mt-3 text-center">
    <h1 class="mt-5">RESPALDO CIENTÍFICO </h1>
    <div class="row">
        <div class="col-sm">
            <h1 class="mt-5 mb-5">EQUIPO CIENTÍFICO DE RENOMBRE INTERNACIONAL </h1>
            
            <p>
                El Consejo Consultor de Nutrición de Herbalife Nutrition 
                está compuesto por destacados expertos de todo el mundo en 
                los campos de nutrición, ciencia y salud. Ellos guían el 
                desarrollo de cada producto y supervisan los estudios 
                clínicos que demuestran la eficacia de cada uno de ellos.<br/><br/>

                Los científicos que lideran el proceso de desarrollo de 
                productos Herbalife Nutrition, son reconocidos expertos en 
                los campos de la medicina, la nutrición y el deporte, 
                quienes además guían el aprendizaje de los Distribuidores 
                independientes sobre los principios de la buena nutrición, 
                la actividad física y un estilo de vida saludable.<br/><br/>
            </p>
            <img src="assets/image/herbalife-medical-experts-staff.png" 
                alt="herbalife medical experts staff"
                class="img-fluid"
            />
        </div>
    </div>
</div>

<div class="row">
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/banana_caramelo.png" 
                alt="Bebida Protéica Batido Nutricional Banana Caramelo 550 g"
                class="img-fluid" 
                width="120px"
            />
        </dd>
        <dd class="">
            <a [routerLink]="['/batidos']">Batido Nutricional Banana Caramelo 550 g</a>
        </dd>
        <dd class="cmp-contentfragment__element-value">
            Código 1522
        </dd>
        <dd class="precio text-center">${{batidos}}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/batidos']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd class="">
            <img src="assets/image/aloe_uva.png" 
                alt="Bebida Digestiva Concentrado de Aloe Vera Uva" 
                class="img-fluid" 
                width="70px"
            />
        </dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/aloes']">Concentrado de Aloe Vera Uva 473 ml</a>
        </dd>
        <dd class="cmp-contentfragment__element-value">
            Código 1610
        </dd>
        <dd class="precio text-center">${{ aloe }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/aloes']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">    
        <dd>
            <img src="/assets/image/serum_reductor.png" 
                alt="Cuidado facial antiarrugas SKIN Serum Reductor de Líneas"
                class="img-fluid"
                width="60px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/cremas']">SKIN Serum Reductor de Líneas 30 ml</a>
        </dd>
        <dd class="cmp-contentfragment__element-value">
            Código 0768
        </dd>
        <dd class="precio text-center">${{ skin768 }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/cremas']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos"> 
        <dd>
            <img src="assets/image/kit_avanzado.png" 
                alt="Cuidado antiedad piel madura KIT Avanzado SKIN" 
                class="img-fluid" 
                width="200px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/cremas']">KIT Avanzado SKIN</a>
        </dd>
        <dd>
            Código 3295
        </dd>
        <dd class="precio text-center">${{ skin3295 }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/cremas']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/acondicionador_fortificante.png"
                alt="Hidratante cuidado de cabello Herbal Aloe Acondicionador Fortificante" 
                class="img-fluid" 
                width="70px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/cremas']">Herbal Aloe Acondicionador Fortificante 250 ml</a>
        </dd>
        <dd>
            Código 2565
        </dd>
        <dd class="precio text-center">${{ herbalAloe }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/cremas']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/choco_avellana.png" 
                alt="Proteína en Polvo Batido Nutricional Choco-avellanas" 
                class="img-fluid" 
                width="100px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/batidos']">Batido Nutricional Choco-avellanas 550 g</a>
        </dd>
        <dd>
            Código 3144
        </dd>
        <dd class="precio text-center">${{ batidos }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/batidos']">Quiero Comprar</a>
        </dd>
    </div>
</div>
<div class="row">
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/aloe_mandarina.png" 
                alt="Bebida instántanea Concentrado de Aloe Vera Mandarina" 
                class="img-fluid" 
                width="60px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/aloes']">Concentrado de Aloe Vera Mandarina 473 ml</a>
        </dd>
        <dd>
            Código 2631
        </dd>
        <dd class="precio text-center">${{ aloe }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/aloes']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/kit_basico_piel_seca.png" 
                alt="Rutina diaria rostro KIT Básico SKIN Piel Seca" 
                class="img-fluid" 
                width="150px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/cremas']">KIT Básico SKIN Piel Seca</a>
        </dd>
        <dd>
            Código 3293
        </dd>
        <dd class="precio text-center">${{ skin3293 }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/cremas']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/canela.png" 
                alt="Batido proteína Batido Nutricional Canela y Especias"
                class="img-fluid" 
                width="100px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/batidos']">Batido Nutricional Canela y Especias 550 g</a>
        </dd>
        <dd>
            Código 2638
        </dd>
        <dd class="precio text-center">${{ batidos }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/batidos']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/gel_refrescante.png" 
                alt="gel hidratante cuidado de piel Herbal Aloe Gel Refrescante Corporal" 
                class="img-fluid" 
                width="80px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/cremas']">Herbal Aloe Gel Refrescante Corporal 200 ml</a>
        </dd>
        <dd>
            Código 2562
        </dd>
        <dd class="precio text-center">${{ herbalAloe }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/cremas']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/crema_manos.png" 
                alt="Cuidado de la piel Herbal Aloe Crema para Manos y Cuerpo" 
                class="img-fluid"
                width="80px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/cremas']">Herbal Aloe Crema para Manos y Cuerpo 200 ml</a>
        </dd>
        <dd>
            Código 2563
        </dd>
        <dd class="precio text-center">${{ herbalAloe }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/cremas']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/fibra_activa-manzana.png" 
                alt="Complemento alimenticio Fibra Activa Manzana" 
                class="img-fluid" 
                width="150px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/fibra']">Fibra Activa Manzana 210 g</a>
        </dd>
        <dd>
            Código 2864
        </dd>
        <dd class="precio text-center">${{ fibra }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/fibra']">Quiero Comprar</a>
        </dd>
    </div>
</div>
<div class="row">
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/sport_activator.png" 
                alt="Suplemento deportistas L-carnitina antioxidantes vitaminas Sport Activator" 
                class="img-fluid"
                width="100px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/nutricion24']">Sport Activator 90 cápsulas</a>
        </dd>
        <dd>
            Código 3123
        </dd>
        <dd class="precio text-center">${{ sportActivator }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/nutricion24']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/kit_basico_piel_grasa.png" 
                alt="Cuidado rutina diaria Kit Básico SKIN Piel Grasa" 
                class="img-fluid"
                width="150px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/cremas']">Kit Básico SKIN Piel Grasa</a>
        </dd>
        <dd>
            Código 3294
        </dd>
        <dd class="precio text-center">${{ skin3294 }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/cremas']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/multivitaminico.png" 
                alt="Suplemento diario y complemento nutritivo Multivitamínco y Minerales" 
                class="img-fluid" 
                width="100px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/suplementos']">Multivitamínco y Minerales 109 g</a>
        </dd>
        <dd>
            Código 3122
        </dd>
        <dd class="precio text-center">${{ multivitaminico }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/suplementos']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/collagen-drink.png" 
                alt="Bebida en polvo de colágeno Collagen Drink Limonada de Fresa" 
                class="img-fluid"
                width="150"/>
        </dd>
        <dd>
            <a [routerLink]="['/suplementos']">Collagen Drink Limonada de Fresa 171 g</a>
        </dd>
        <dd>
            Código 3987
        </dd>
        <dd class="precio text-center">${{ collage }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/suplementos']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/shampoo_fortificante.png" 
                alt="Cuidado del cabello Herbal Aloe Shampoo Fortificante" 
                class="img-fluid" 
                width="70"
        />
        </dd>
        <dd>
            <a [routerLink]="['/cremas']">Herbal Aloe Shampoo Fortificante 250 ml</a>
        </dd>
        <dd>
            Código 2564
        </dd>
        <dd class="precio text-center">${{ herbalAloe }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/cremas']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/limpiador-de-sabila.png" 
                alt="Cuidado de la piel aloe vera SKIN Limpiador Relajante de Sábila" 
                class="img-fluid" 
                width="80"/>
        </dd>
        <dd>
            <a [routerLink]="['/cremas']">SKIN Limpiador Relajante de Sábila 150 ml</a>
        </dd>
        <dd>
            Código 0765
        </dd>
        <dd class="precio text-center">${{ skin765 }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/cremas']">Quiero Comprar</a>
        </dd>
    </div>
</div>
<div class="row">
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/jabon_liquido.png" 
                alt="Herbal Aloe Jabón Líquido Manos y Cuerpo" 
                class="img-fluid"
                width="70px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/cremas']">Herbal Aloe Jabón Líquido Manos y Cuerpo 250 ml</a>
        </dd>
        <dd>
            Código 2561
        </dd>
        <dd class="precio text-center">${{ herbalAloe }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/cremas']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/te_original.png" 
                alt="Té y Hierbas Instantáneo Original" 
                class="img-fluid"
                width="100px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/tes']">Té y Hierbas Instantáneo Original 102 g</a>
        </dd>
        <dd>
            Código 0106
        </dd>
        <dd class="precio text-center">${{ teOriginal }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/tes']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/nutrisoup.png" 
                alt="NutriSoup Pollo con Vegetales" 
                class="img-fluid" 
                width="100px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/suplementos']">NutriSoup Pollo con Vegetales 416 gr</a>
        </dd>
        <dd>
            Código 0748
        </dd>
        <dd class="precio text-center">${{ nutriSoup }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/suplementos']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/beverage-mix.png" 
                alt="Beverage Mix Mix Frutal" 
                class="img-fluid"
                width="150"/>
        </dd>
        <dd>
            <a [routerLink]="['/proteinas']">Beverage Mix Mix Frutal 280 g</a>a>
        </dd>
        <dd>
            Código 093K
        </dd>
        <dd class="precio text-center">${{ beverage }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/proteinas']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/proteina-polvo-personalizada.png" 
                alt="Proteína en Polvo Personalizada" 
                class="img-fluid" 
                width="150"
        />
        </dd>
        <dd>
            <a [routerLink]="['/proteinas']">Proteína en Polvo Personalizada 240 g</a>
        </dd>
        <dd>
            Código 0242
        </dd>
        <dd class="precio text-center">${{ ppp }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/proteinas']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/limpiador_citrico.png" 
                alt="SKIN Limpiador Cítrico para la Piel" 
                class="img-fluid" 
                width="70"/>
        </dd>
        <dd>
            <a [routerLink]="['/cremas']">SKIN Limpiador Cítrico para la Piel 150 ml</a>
        </dd>
        <dd>
            Código 0766
        </dd>
        <dd class="precio text-center">${{ skin766 }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/cremas']">Quiero Comprar</a>
        </dd>
    </div>
</div>
<div class="row">
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/dulce_de_leche.png" 
                alt="Batido Nutricional Dulce de Leche 550 g" 
                class="img-fluid"
                width="100px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/batidos']">Batido Nutricional Dulce de Leche 550 g</a>
        </dd>
        <dd>
            Código 0940
        </dd>
        <dd class="precio text-center">${{ batidos }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/batidos']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/te_frambuesa.png" 
                alt="Té y Hierbas Instantáneo Frambuesa" 
                class="img-fluid"
                width="100px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/tes']">Té y Hierbas Instantáneo Frambuesa 51 g</a>
        </dd>
        <dd>
            Código 0256
        </dd>
        <dd class="precio text-center">$25.500.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/tes']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/crema_hidratante_contorno_de_ojos.png" 
                alt="SKIN Crema Hidratante para Contorno de Ojos" 
                class="img-fluid" 
                width="60px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/cremas']">SKIN Crema Hidratante para Contorno de Ojos 15 ml</a>
        </dd>
        <dd>
            Código 0771
        </dd>
        <dd class="precio text-center">${{ skin771 }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/cremas']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/reubuild.png" 
                alt="Herbalife24 Rebuild Strength Chocolate" 
                class="img-fluid"
                width="90"/>
        </dd>
        <dd>
            <a [routerLink]="['/nutricion24']">Herbalife24 Rebuild Strength Chocolate 1000 g</a>
        </dd>
        <dd>
            Código 1417
        </dd>
        <dd class="precio text-center">${{ herbalife24 }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/nutricion24']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/te_chai.png" 
                alt="Té y Hierbas Chai" 
                class="img-fluid" 
                width="100"
        />
        </dd>
        <dd>
            <a [routerLink]="['/tes']">Té y Hierbas Chai 51 g</a>
        </dd>
        <dd>
            Código 1638
        </dd>
        <dd class="precio text-center">${{ teChai }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/tes']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/xtra_cal.png" 
                alt="XTRA-CAL" 
                class="img-fluid" 
                width="90"/>
        </dd>
        <dd>
            <a [routerLink]="['/suplementos']">XTRA-CAL 126.8 g</a>
        </dd>
        <dd>
            Código 0020
        </dd>
        <dd class="precio text-center">${{ xtraCal }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/suplementos']">Quiero Comprar</a>
        </dd>
    </div>
</div>
<div class="row">
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/frutilla.png" 
                alt="Batido Nutricional Frutilla" 
                class="img-fluid"
                width="100px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/batidos']">Batido Nutricional Frutilla 550 g</a>
        </dd>
        <dd>
            Código 0143
        </dd>
        <dd class="precio text-center">${{ batidos }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/batidos']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/crema_humectante.png" 
                alt="SKIN Crema Humectante Protectora de Día SPF30" 
                class="img-fluid"
                width="60px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/cremas']">SKIN Crema Humectante Protectora de Día SPF30 30 ml</a>
        </dd>
        <dd>
            Código 0899
        </dd>
        <dd class="precio text-center">${{ skin899 }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/cremas']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/cr7_drive.png" 
                alt="CR7 Drive Acaí Frutos Rojos" 
                class="img-fluid" 
                width="130px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/nuticion24']">CR7 Drive Acaí Frutos Rojos 810 g</a>
        </dd>
        <dd>
            Código 1478
        </dd>
        <dd class="precio text-center">${{ cr7 }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/nuticion24']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/gel_contorno_de_ojos.png" 
                alt="SKIN Gel Reafirmante para Contorno de Ojos" 
                class="img-fluid"
                width="60"/>
        </dd>
        <dd>
            <a [routerLink]="['/cremas']">SKIN Gel Reafirmante para Contorno de Ojos 15 ml</a>
        </dd>
        <dd>
            Código 0770
        </dd>
        <dd class="precio text-center">${{ skin770 }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/cremas']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/aloe_mango.png" 
                alt="Concentrado de Aloe Vera Mango" 
                class="img-fluid" 
                width="60"
        />
        </dd>
        <dd>
            <a [routerLink]="['/aloes']">Concentrado de Aloe Vera Mango 473 ml</a>
        </dd>
        <dd>
            Código 1065
        </dd>
        <dd class="precio text-center">${{ aloe }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/aloes']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/naranja_crema.png" 
                alt="Batido Nutricional Naranja Crema" 
                class="img-fluid" 
                width="100"/>
        </dd>
        <dd>
            <a [routerLink]="['/batidos']">Batido Nutricional Naranja Crema 550 g</a>
        </dd>
        <dd>
            Código 1134
        </dd>
        <dd class="precio text-center">${{ batidos }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/batidos']">Quiero Comprar</a>
        </dd>
    </div>
</div>
<div class="row">
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/tonificador_energizante.png" 
                alt="SKIN Tonificador Energizante de Hierbas" 
                class="img-fluid"
                width="60px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/cremas']">SKIN Tonificador Energizante de Hierbas 50 ml</a>
        </dd>
        <dd>
            Código 0767
        </dd>
        <dd class="precio text-center">${{ skin767 }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/cremas']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/exfoliante_arandanos.png" 
                alt="SKIN Exfoliante Instantáneo de Arándanos" 
                class="img-fluid"
                width="100px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/cremas']">SKIN Exfoliante Instantáneo de Arándanos 120 ml</a>
        </dd>
        <dd>
            Código 0772
        </dd>
        <dd class="precio text-center">${{ skin772 }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/cremas']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/chocolate.png" 
                alt="Batido Nutricional Chocolate" 
                class="img-fluid" 
                width="100px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/batidos']">Batido Nutricional Chocolate 572g</a>
        </dd>
        <dd>
            Código 0142
        </dd>
        <dd class="precio text-center">${{ batidos }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/batidos']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/n-r-g.png" 
                alt="NRG Mezcla de Té Original" 
                class="img-fluid"
                width="90"/>
        </dd>
        <dd>
            <a [routerLink]="['/suplementos']">NRG Mezcla de Té Original 60 g</a>
        </dd>
        <dd>
            Código 0102
        </dd>
        <dd class="precio text-center">${{ nrg }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/suplementos']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/te_durazno.png" 
                alt="Té y Hierbas Instantáneo Durazno" 
                class="img-fluid" 
                width="90"
        />
        </dd>
        <dd>
            <a [routerLink]="['/tes']">Té y Hierbas Instantáneo Durazno 51 g</a>
        </dd>
        <dd>
            Código 0257
        </dd>
        <dd class="precio text-center">${{ teDurazno }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/tes']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/protein-drink-mix.png" 
                alt="Protein Drink Mix - PDM Vainilla" 
                class="img-fluid" 
                width="100"/>
        </dd>
        <dd>
            <a [routerLink]="['/proteinas']">Protein Drink Mix - PDM Vainilla 616 g</a>
        </dd>
        <dd>
            Código 1122
        </dd>
        <dd class="precio text-center">${{ pdm }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/proteinas']">Quiero Comprar</a>
        </dd>
    </div>
</div>
<div class="row">
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/crema_renovadora_de_noche.png" 
                alt="SKIN Crema Renovadora de Noche" 
                class="img-fluid"
                width="60px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/cremas']">SKIN Crema Renovadora de Noche 30 ml</a>
        </dd>
        <dd>
            Código 0774
        </dd>
        <dd class="precio text-center">${{ skin774 }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/cremas']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/herbalife-line.png" 
                alt="Herbalifeline" 
                class="img-fluid"
                width="120px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/suplementos']">Herbalifeline 71.1 g</a>
        </dd>
        <dd>
            Código 0065
        </dd>
        <dd class="precio text-center">${{ herbalifeLine }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/suplementos']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/cookies_&_cream.png" 
                alt="Batido Nutricional Cookies and Cream" 
                class="img-fluid" 
                width="120px"
            />
        </dd>
        <dd>
            <a [routerLink]="['/batidos']">Batido Nutricional Cookies and Cream 550 g</a>
        </dd>
        <dd>
            Código 0146
        </dd>
        <dd class="precio text-center">${{ batidos }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/batidos']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/te_limon.png" 
                alt="Té y Hierbas Instantáneo Limón" 
                class="img-fluid"
                width="100"/>
        </dd>
        <dd>
            <a [routerLink]="['/tes']">Té y Hierbas Instantáneo Limón 51 g</a>
        </dd>
        <dd>
            Código 0255
        </dd>
        <dd class="precio text-center">${{ teLimon }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/tes']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
        <dd>
            <img src="assets/image/mascarilla_arcilla_menta.png" 
                alt="SKIN Mascarilla Purificadora de Arcilla con Menta 120 ml" 
                class="img-fluid" 
                width="90"
        />
        </dd>
        <dd>
            <a [routerLink]="['/cremas']">SKIN Mascarilla Purificadora de Arcilla con Menta 120 ml</a>
        </dd>
        <dd>
            Código 0773
        </dd>
        <dd class="precio text-center">${{ skin773 }}.-</dd>
        <dd class="cmp-contentfragment__element-value">
            <a [routerLink]="['/cremas']">Quiero Comprar</a>
        </dd>
    </div>
    <div class="col-sm box-productos">
       
    </div>
</div>