<div class="container">
    <div class="row">
        <div class="col-sm box-articulo-detalle">
            <div class="box-articulo-title-detalle">
                <h1>CREMA DE ZAPALLO</h1>
            </div>
            <p class="text-center">
                <img src="assets/image/crema_de_zapallo.png" 
                    alt="Crema de zapallo"
                    class="img-fluid"
                />
            </p>
            <i>
                Porciones: 1<br/>
                Tiempo de preparación: 20 minutos<br/>
                Tiempo de Cocción: 10 minutos<br/>
            </i>
            <br>
            <h5>Ingredientes</h5>
            <ul>
                <li>250 g. zapallo en cubos</li>
                <li>1 taza caldo de verduras bajo en grasa</li>
                <li>2 cucharadas proteína en polvo personalizada</li>
                <li>2 cucaharadas queso untable bajo en grasa</li>
                <li>sal y pimienta</li>
            </ul>
            <h5>Método de preparación:</h5>
            <p></p>
            <h5>PREPARACIÓN</h5>
            <p>
                Una genial idea para sumar proteínas a nuestras comidas. 
                Agrega el zapallo y el caldo en una olla hasta hervir. 
                Licua los ingredientes y agrega la Proteína en Polvo 
                Personalizada de Herbalife Nutrición, queso, sal y 
                pimienta. Mezclar bien y servir.
            </p>
            <h5>Nutrientes por porción</h5>
            <table class="table">
                <th></th>
                <th>Calorías (kcal)</th>
                <th>Grasas (g)</th>
                <th>Carbohidratos (g)</th>
                <th>Proteína (g)</th>
                <tr></tr>
                <td>Por porción</td>
                <td>104 cal.</td>
                <td>5.5 g.</td>
                <td>16,5 g.</td>
                <td>9.7 g.</td>
            </table>
            <hr>
            <i>Puedes decorar con semillas de zapallo.</i>
        </div>
    </div>
</div>
