<div class="container mt-3">
    <h1 class="text-center">Bebidas Instantaneas (Té)</h1>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm box-tes">
            <div class="box-tes-header text-center">Té y Hierbas Instantáneo</div>
            <div class="box-tes-body">
                <h5 class="box-tes-title">Té y Hierbas Instantáneo Original 102 g</h5>
                <h6 class="box-tes-subtitle">Código 0106 </h6>
                <p class="text-center">
                    <img src="assets/image/te_original.png" 
                        alt="Té y Hierbas Instantáneo Original"
                        class="img-fluid"
                        style="width: 150px;"> 
                </p>
                <p class="text-center precio">${{ teHierba }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>Esta deliciosa bebida en polvo combina té verde, té naranja 
                        pekoe y hierbas, que logran un sabor agradable.
                    </li>
                    <li>El té verde es conocido por sus propiedades antioxidantes.</li>
                    <li>Baja en calorías.</li>
                    <li>Ideal para acompañar su comida o merienda.</li>
                </ul>
                <!-- <div class="box-tes-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="RY4YXJE9796DY">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>
        <div class="col-sm box-tes">
            <div class="box-tes-header text-center">Té y Hierbas Instantáneo</div>
            <div class="box-tes-body">
                <h5 class="box-tes-title">Té y Hierbas Instantáneo Frambuesa 51 g</h5>
                <h6 class="box-tes-subtitle">Código 0256 </h6>
                <p class="text-center">
                    <img src="assets/image/te_frambuesa.png" 
                        alt="Té y Hierbas Instantáneo Frambuesa"
                        class="img-fluid"
                        style="width: 150px;"> 
                </p>
                <p class="text-center precio">${{ tes }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>Esta deliciosa bebida en polvo combina té verde, té naranja 
                        pekoe y hierbas, que logran un sabor agradable.
                    </li>
                    <li>El té verde es conocido por sus propiedades antioxidantes.</li>
                    <li>Baja en calorías.</li>
                    <li>Ideal para acompañar su comida o merienda.</li>
                </ul>
                <!-- <div class="box-tes-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="HLL9USZTMHCLC">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>    
                </div> -->
            </div>
        </div>
        <div class="col-sm box-tes">
            <div class="box-tes-header text-center">Té y Hierbas Instantaneo</div>
            <div class="box-tes-body">
                <h5 class="box-tes-title">Té y Hierbas Instantaneo Chai 51gr</h5>
                <h6 class="box-tes-subtitle">Código 1638 </h6>
                <p class="text-center">
                    <img src="assets/image/te_chai.png" 
                        alt="Té y Hierbas Chai 51gr"
                        class="img-fluid"
                        style="width: 150px;"> 
                </p>
                <p class="text-center precio">${{ tes }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>Esta deliciosa bebida en polvo combina té verde, té naranja pekoe y 
                        hierbas, que logran un sabor agradable.
                    </li>
                    <li>El té verde es conocido por sus propiedades antioxidantes.</li>
                    <li>Baja en calorías.</li>
                    <li>Ideal para acompañar su comida o merienda.</li>
                </ul>
                <!-- <div class="box-tes-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="BVPAVTCGS3QPW">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>    
                </div> -->
            </div>
        </div>
        <div class="col-sm box-tes">
            <div class="box-tes-header text-center">Té y Hierbas Instantáneo</div>
            <div class="box-tes-body">
                <h5 class="box-tes-title">Té y Hierbas Instantáneo Durazno 51 g</h5>
                <h6 class="box-tes-subtitle">Código 0257 </h6>
                <p class="text-center">
                    <img src="assets/image/te_durazno.png" 
                        alt="Té y Hierbas Instantáneo Durazno 51 g"
                        class="img-fluid"
                        style="width: 150px;"> 
                </p>
                <p class="text-center precio">${{ tes }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>Esta deliciosa bebida en polvo combina té verde, té naranja pekoe y 
                        hierbas, que logran un sabor agradable.
                    </li>
                    <li>El té verde es conocido por sus propiedades antioxidantes.</li>
                    <li>Baja en calorías.</li>
                    <li>Ideal para acompañar su comida o merienda.</li>
                </ul>
                <!-- <div class="box-tes-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="HAPF9RD6HPSVJ">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>      
                </div> -->
            </div>
        </div>
        <div class="col-sm box-tes">
            <div class="box-tes-header text-center">Té y Hierbas Instantáneo</div>
            <div class="box-tes-body">
                <h5 class="box-tes-title">Té y Hierbas Instantáneo Limón 51 g</h5>
                <h6 class="box-tes-subtitle">Código 0255 </h6>
                <p class="text-center">
                    <img src="assets/image/te_limon.png" 
                        alt="Té y Hierbas Instantáneo limon 51 g"
                        class="img-fluid"
                        style="width: 150px;"> 
                </p>
                <p class="text-center precio">${{ tes }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>Esta deliciosa bebida en polvo combina té verde, té naranja pekoe y 
                        hierbas, que logran un sabor agradable.
                    </li>
                    <li>El té verde es conocido por sus propiedades antioxidantes.</li>
                    <li>Baja en calorías.</li>
                    <li>Ideal para acompañar su comida o merienda.</li>
                </ul>
                <!-- <div class="box-tes-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="69WBRF2FNKG8W">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>    
                </div> -->
            </div>
        </div>
    </div>
</div>
