<div class="container mt-3 text-center">
    <h1 class="text-center">Nutrición Deportiva</h1>
</div>
<div class="box-deportista">
    <div class="row">
        <div class="col-sm">
            <p>
                Entendemos que mantenerte activo es un estilo de vida. 
                Los productos de Deportes y Vida Activa de Herbalife Nutrition, <br/>
                te ayudarán a mantenerte siempre activo cuando lo necesites.
            </p>
            <p>    
                Herbalife24 es una línea de nutrición para el rendimiento 
                que potencia a los deportistas 24 horas al día, diseñada 
                para ayudarte a entrenar, recuperar y desempeñar tu rutina 
                con todo el apoyo nutricional que necesitas como deportista.
            </p>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="sm">
            <div class="box-img-deportista">
                <img src="assets/image/nutrición_antes.png" 
                    alt="Nutrición antes de hacer deporte"
                    class="img-fluid"
                />
            </div>
        </div>
        <div class="sm">
            <div class="box-img-deportista">
                <img src="assets/image/nutrición_durante.png" 
                    alt="Nutrición durate los ejercicios"
                    class="img-fluid"
                />
            </div>
        </div>
        <div class="sm">
            <div class="box-img-deportista">
                <img src="assets/image/nutrición_espués.png" 
                    alt="Nutrición despues de hacer deporte"
                    class="img-fluid"
                />
            </div>
        </div>
    </div>
</div>





