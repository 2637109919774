<div class="container text-center">
    <div class="alert alert-danger">
        <h1 class="rojo txt-l">
            Se ha producido un ERROR en la Operación, o
            Usted ha Cancelado la operación de Pago
        </h1>
    </div>
    <hr class="linea"/>
    <h2 class="azul text-m">
        Puede intentarlo ahora, o volver mas tarde para efectuar la
        operación de la compra nuevamente
    </h2>
    <p class="azul text-m">
        Si persiste el error comuniquese por Email<span class="contacto"></span>
    </p>
    <hr class="linea"/>
    <p class="text-m">Gracias por su prefencia</p>
    <p class="text-m">Tenga un cordial saludo</p>
</div>
