<div class="container">
    <div class="row">
        <div class="col-sm box-articulo-detalle">
            <div class="box-articulo-title-detalle">
                <h1>BATIDO DE REBUILD Y FRUTOS DEL BOSQUE</h1>
            </div>
            <p class="text-center">
                <img src="assets/image/rebuild_frutos_rojos.png" 
                    alt="BATIDO DE REBUILD Y FRUTOS DEL BOSQUE"
                    class="img-fluid"
                />
            </p>
            <i>
                Porciones: 1<br/>
                Tiempo de preparación: 5 minutos<br/>
            </i>
            <br>
            <h5>Ingredientes</h5>
            <ul>
                <li>1 taza leche descremada</li>
                <li>2 cucharadas rebuild strength</li>
                <li>1 taza frambuesas o arándanos</li>
                <li>1/2 cucharada canela</li>
                <li>pizca pimienta cayena</li>
                <li>pizca cúrcuma</li>
            </ul>
            <h5>Método de preparación:</h5>
            <p></p>
            <h5>PREPARACIÓN</h5>
            <p>
                Vierte todos los ingredientes en la licuadora uno a uno 
                según el orden de ingredientes y mezcla bien. Disfruta 
                este batido 30 minutos después de hacer ejercicios para 
                potenciar tu recuperación.
            </p>
            <h5>Nutrientes por porción</h5>
            <table class="table">
                <th></th>
                <th>Calorías (kcal)</th>
                <th>Grasas (g)</th>
                <th>Carbohidratos (g)</th>
                <th>Azúcar (g)</th>
                <th>Fibra (g)</th>
                <th>Proteína (g)</th>
                <tr></tr>
                <td>Por porción</td>
                <td>360 cal.</td>
                <td>4.6 g.</td>
                <td>47 g.</td>
                <td>0 g.</td>
                <td>0 g.</td>
                <td>35 g.</td>
            </table>
            <hr>
            <i>Valores nutricionales referenciales.</i>
        </div>
    </div>
</div>
