<div class="container mt-3 text-center">
    <h1 class="text-center">Desayuno Ideal</h1>
</div>
<div class="box-desayuno">
    <div class="row">
        <div class="col-sm">
            <p>
                Alcanzar tu meta requerirá mucha fuerza de voluntad y 
                determinación, para seguir el camino hacia una nueva y 
                saludable versión de ti. <br/>
                La nutrición de Herbalife Nutrition puede facilitar ese 
                viaje y proporcionarte todo lo que necesitas para crear 
                un plan de alimentación saludable y personalizado.
            </p>
            <p>    
                Nuestros productos se basan en la ciencia y proporcionan 
                los nutrientes de alta calidad que tu cuerpo necesita.
            </p>
            <p>    
                *Está clínicamente probado que el Batido Nutricional se trata de una opción saludable, rica en nutrientes, y puede ayudar a controlar el peso.
            </p>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="sm">
            <div class="box-img-desayuno">
                <img src="assets/image/batidos_desayuno.png" 
                    alt="Batidos al Desayuno"
                    class="img-fluid"
                />
            </div>
        </div>
        <div class="sm">
            <div class="box-img-desayuno">
                <img src="assets/image/tés.png" 
                    alt="Batidos al Desayuno"
                    class="img-fluid"
                />
            </div>
        </div>
        <div class="sm">
            <div class="box-img-desayuno">
                <img src="assets/image/jar-and-glass-with-water-and-aloe-ch.png" 
                    alt="Batidos al Desayuno"
                    class="img-fluid"
                />
            </div>
        </div>
    </div>
</div>





