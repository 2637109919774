import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proteinas',
  templateUrl: './proteinas.component.html',
  styleUrls: ['./proteinas.component.css']
})
export class ProteinasComponent implements OnInit {

  beverage: string;
  pdm: string;
  ppp: string;

  constructor() {
    this.beverage = '29.000';
    this.pdm = '41.900';
    this.ppp = '26.100';
  }

  ngOnInit(): void {
  }

}
