<div class="container mt-3 text-center">
    <img src="assets/image/new-proteina.png" 
        alt="Nueva Proteina Herbalife"
        class="img-fluid"
    />
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm box-proteina">
            <div class="box-proteina-header text-center">Beverage Mix </div>
            <div class="box-proteina-body">
                <h5 class="box-proteina-title">Proteina en polvo 280 gs.</h5>
                <h6 class="box-proteina-subtitle">Código 093K </h6>
                <p class="text-center"> 
                    <img src="assets/image/beverage-mix.png" 
                        alt="proteina en polvo"
                        style="width: 180px;"> 
                </p>
                <p class="precio text-center">${{ beverage }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul>Beneficios
                    <li>Excelente fuente de proteína</li>
                    <li>Libre de azúcar</li>
                    <li>Libre de grasa total</li>
                    <li>Bajo en sodio</li>
                    <li>Sabor: Mix Frutal</li>
                </ul>
                <!-- <div class="box-proteina-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="BEL3UHF5SNE2A">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>
        
        <div class="col-sm box-proteina">
            <div class="box-proteina-header text-center">Protein Drink Mix </div>
            <div class="box-proteina-body">
                <h5 class="box-proteina-title">Protein Drink Mix 610 gs.</h5>
                <h6 class="box-proteina-subtitle">Código 1122 </h6>
                <p class="text-center"> 
                    <img src="assets/image/protein-drink-mix.png" 
                        alt="protein drink mix"
                        style="width: 150px;"> 
                </p>
                <p class="precio text-center">${{ pdm }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul>Beneficios:
                    <li>Alimento en polvo fortificado con fósforo y 10 vitaminas y suplementado con 7 minerales y 2 vitaminas. </li>
                    <li>Contribuye a la ingesta diaria de proteínas.</li>
                    <li>Con vitaminas antioxidantes C y E. </li>
                    <li>Es libre de gluten.</li>
                </ul>
                <!-- <div class="box-proteina-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="PJEAE8KSPJXRJ">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>
        <div class="col-sm box-proteina">
            <div class="box-proteina-header text-center">Proteina en Polvo Personalizada </div>
            <div class="box-proteina-body">
                <h5 class="box-proteina-title">Proteina en Polvo Personalizada 240 gs.</h5>
                <h6 class="box-proteina-subtitle">Código 0242 </h6>
                <p class="text-center"> 
                    <img src="assets/image/proteina-polvo-personalizada.png" 
                        alt="protein drink mix"
                        style="width: 150px;"> 
                </p>
                <p class="precio text-center">$26.100.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul>Beneficios:
                    <li>Combinación de proteína de soya y suero de leche</li>
                    <li>Se disuelve fácilmente con bebidas frías y calientes</li>
                    <li>Una forma fácil y divertida de incrementar tu ingesta diaria de proteína</li>
                </ul>
                <!-- <div class="box-proteina-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="EJAWQGXMG6HYN">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</div>
