<div class="container title-negocio">
    <h1 class="text-center articulo-title">CONSTRUYE TU PROPIO NEGOCIO</h1>
    <h2 class="text-center">OBTENDRÁS GANANCIAS ADICIONALES </h2>
</div>
<div class="container">
    <div class="row">
        <div class="col-sm">
            <p class="text-center">
                ¡Nuestro sistema es uno de los mejores del mercado! Puedes 
                obtener hasta el 50% de ganancias por la venta directa de 
                los productos y además podrías recibir Regalías*. 
                Tus ganancias irán en función a tu trabajo, esfuerzo y 
                dedicación, ¡no existen límites para tu espíritu 
                emprendedor!
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-sm box-negocio">
            <div class="img-negocio">
                <img src="assets/image/group-of-people-thumbs-up.png" 
                    alt="group-of-people-thumbs-up"
                    class="img-fluid"
                />
            </div>
            <h2 class="title-box-negocio text-center">
                ESTARÁS ORGULLOSO DE SER PARTE DE LA COMUNIDAD 
                HERBALIFE NUTRITION
            </h2>
            <p>
                Somos una de las marcas de nutrición, multinivel y venta 
                directa más grandes del mundo. Contamos con un reconocido 
                equipo de científicos de renombre y ofrecemos productos en 
                más de 90 países del mundo.
            </p>
            <div class="img-negocio">
                <img src="assets/image/hands-clapping.png" 
                    alt="hands clapping"
                    class="img-fluid"
                />
            </div>
            <h2 class="title-box-negocio text-center">
                PODRÍAS RECIBIR PREMIOS, VIAJES Y RECONOCIMIENTOS
            </h2>
            <p>
                Herbalife Nutrition te da la oportunidad de recibir 
                maravillosos premios, ser reconocido por otros 
                Distribuidores Independientes y viajar por diferentes 
                países del mundo. Todo ello según tu trabajo, esfuerzo y 
                dedicación. ¿Puedes imaginarlo?
            </p>
        </div>
        <div class="col-sm box-negocio">
            <div class="img-negocio">
                <img src="assets/image/mother-holding-a-baby.png" 
                    alt="group-of-people-thumbs-up"
                    class="img-fluid"
                />
            </div>
            <h2 class="title-box-negocio text-center">
                TENDRÁS FLEXIBILIDAD DE HORARIOS
            </h2>
            <p>
                Tú eliges cómo, cuándo y dónde desarrollar tu negocio 
                independiente. Decídete a ser tu propio jefe y tendrás 
                la flexibilidad de horarios que te permitirá gozar de 
                calidad de tiempo para hacer tus actividades cotidianas y 
                estar con tus seres queridos.
            </p>
            <div class="img-negocio">
                <img src="assets/image/flying-plane.png" 
                    alt="flying plane"
                    class="img-fluid"
                />
            </div>
            <h2 class="title-box-negocio text-center">
                PUEDES LLEVAR TU NEGOCIO A NIVEL INTERNACIONAL
            </h2>
            <p>
                Una de las grandes ventajas de la oportunidad de negocio 
                independiente Herbalife Nutrition, es que podrías expandir 
                tu negocio a más de 90 países y con ello, también 
                incrementar tus posibilidades de hacer crecer tus ganancias.
            </p>
        </div>
    </div>
</div>
<div class="box-container">
    <div class="row">
        <div class="col-sm-4 offset-sm-2 box-objetivos">
            <img src="assets/image/greentick.png" 
                alt="ticket verde" 
                width="80"
                class="img-ticket img-fluid"
            />
            <h2>ESTABLECE TUS <br/>OBJETIVOS </h2>
            <p>
                Establece exactamente lo que quieres sacar de tu negocio 
                para saber exactamente dónde poner tus esfuerzos.
            </p>
        </div>
        <div class="col-sm-3 box-objetivos">
            <img src="assets/image/greentick.png" 
                alt="ticket verde" 
                width="80"
                class="img-ticket img-fluid"
            />
            <h2>APRENDE SOBRE EL <br/>NEGOCIO  </h2>
            <p>
                Aprende sobre los productos al detalle, para que puedas 
                compartir los beneficios y educar a tus clientes.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 offset-sm-2 box-objetivos">
            <img src="assets/image/greentick.png" 
                alt="ticket verde" 
                width="80"
                class="img-ticket img-fluid"
            />
            <h2>ESCUCHA Y APRENDE</h2>
            <p>
                Interésate por tus clientes. Averigua cuál es su 
                objetivo y muéstrales cómo puedes ayudarles a alcanzarlo.
            </p>
        </div>
        <div class="col-sm-3 box-objetivos">
            <img src="assets/image/greentick.png" 
                alt="ticket verde" 
                width="80"
                class="img-ticket img-fluid"
            />
            <h2>COMPARTE TUS <br/>RESULTADOS   </h2>
            <p>
                Sé "producto del producto". Comparte tus resultados 
                y haz que todos quieran estar donde tú estás.
            </p>
        </div>
    </div>
</div>
<div class="box-negocio-contacto">
    <div class="container">
        <p text-center>
            Si deseas iniciar tu propio negocio independiente,
            envianos un mensaje por whatsapp y te ofreceremos
            una atención personalizada como te mereces. o envianos 
            un Email <i class="contacto"></i> 
        </p>

    </div>
</div>

