<div class="container">
    <div class="row">
        <div class="col-sm box-articulo-detalle">
            <div class="box-articulo-title-detalle">
                <h1>
                    Dieta de Moda: <br/>
                    ¿Es el Ayuno Intermitente una buena
                    alternativa para Bajar de Peso?
                </h1>
                <span>Autor: Herbalife Nutrition</span>
                <p class="text-center">
                    <img src="assets/image/woman-eating-a-salad.png" 
                        alt="woman eating a salad"
                        class="img-fluid"
                    />
                </p>
            </div>
            <p>
                Seguramente ya escuchó por ahí sobre la dieta de ayuno 
                intermitente. Chilenos como Gary Medel o Giancarlo Petaccia 
                la han popularizado atribuyendo su baja de peso a este régimen 
                alimenticio. Básicamente se trata de dejar de comer varias horas 
                al día, o días a la semana. Por ejemplo, existe la modalidad 5/2, 
                que consiste en ayunar 2 días no consecutivos, pero la que más 
                popularidad ha alcanzado, es el 8/16 o ayuno intermitente diario, 
                donde puedes comer sólo 8 horas al día y ayunar las 16 siguientes, 
                que incluye las horas que pasamos durmiendo, lo que facilitaría su 
                cumplimiento.
            </p>
            <p>
                Esta dieta, como todas las de moda, no ha estado libre de 
                críticas. De defensores y detractores. Un reportaje del New 
                York Times* tras consultar a varios especialistas mundiales, 
                concluyó que esta dieta sí resulta, pero que ofrece una pérdida 
                de peso similar a cualquier otra dieta de reducción de calorías. 
                “La mejor dieta es aquella en la que estás sano, hidratado y viviendo 
                tu mejor vida. Si el ayuno funciona para ti, hágalo”, consigna el 
                artículo.
            </p>
            <p>
                Con esta conclusión coincide el médico pediatra, doctor 
                Pedro Barreda, miembro del Consejo Consultor de Nutrición 
                de Herbalife Nutrition y también autor del libro ¡Olvídese 
                de las calorías! Coma sano y natural.
            </p>
            <p>
                “El problema del sobrepeso y obesidad es tan grande que cualquier cosa 
                llama la atención. Y cualquier cosa que pudiera solucionarlo, aún más. 
                Puede probar, ver si le da resultado, que escuche a su cuerpo, porque 
                debemos darle a la gente una ventana de opciones en un ambiente que es 
                obesógeno y sin movimiento. Pero lo realmente importante acá es entender 
                que la obesidad es una enfermedad y debe ser tratada como tal. No podemos 
                guiarnos por internet, lo que es bueno para uno, no necesariamente 
                lo es para otro”.
            </p>
            <p>
                El especialista en nutrición es claro en manifestar que lo importante es 
                estar enfocado en la pérdida de grasa y no de músculo, y muchas dietas 
                restrictivas consiguen justamente lo segundo, sin favorecer la pérdida de 
                grasa corporal, cuyo exceso es lo dañino para la salud. Por eso es importante 
                conocer nuestro metabolismo basal, pues si ingerimos menos calorías de las que 
                necesitamos para las funciones básicas del cuerpo, como respirar o latir del 
                corazón, las personas no solo pierden musculatura, sino que además no lo resisten 
                y viene el famoso “rebote” de las dietas.
            </p>
            <p>
                “Nuestro organismo no puede estar ni un segundo sin el aporte de energía a 
                través de la glucosa. Entonces, deposita en el hígado y en los músculos una 
                reserva que se llama glucógeno. La energía para nuestras funciones básica 
                durante la noche -cuando el cuerpo repara lo dañado durante el día- la saca 
                del hígado. Sin embargo, lo acumulado en los músculos, solo se consume a través 
                de la actividad de los mismos, y si no se utiliza, se convierte en grasa”.
            </p>
            <p>
                Al margen de las dietas de moda, el doctor Pedro Barreda prefiere hablar de una 
                buena nutrición. Una que se adopte como estilo de vida, no como algo temporal y 
                que incluya el movimiento y la actividad física. Una nutrición basada en los 
                macronutrientes: proteínas, lípidos y carbohidratos. Los primeros, intocables y 
                claves para las funciones del organismo, generar saciedad y mantener la musculatura; 
                los segundos, privilegiando siempre las grasas saludables como salmón, frutos secos 
                o palta. Y los terceros, con restricciones, pero no eliminarlos. Preferir los integrales 
                y evitar las harinas refinadas y azúcares.
            </p>
            <p>
                “Si una persona quiere bajar su porcentaje de grasa, no debe pasar hambre. Cuando 
                pasa hambre con una dieta, debe cambiar de opinión, porque los alimentos adecuados 
                para una dieta son justamente aquellos que no hacen pasar hambre, como las 
                proteínas, grasas y carbohidratos con restricción”.
                <a href="http://www.facebook.com/sharer.php?u=http://www.mipesoideal.com/detalledieta/"
                    target="_blank">
                    <img src="assets/image/facebook.png" 
                        alt="Compartir en Facebook el articulo Dieta de Moda"
                        class="img-fluid compartir"
                        width="50"
                    />
                </a>
            </p>
        </div>
    </div>
</div>