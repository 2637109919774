<div class="container">
    <div class="row">
        <div class="col-sm box-articulo-detalle">
            <div class="box-articulo-title-detalle">
                <h1>WAFFLES REBUILD</h1>
            </div>
            <p class="text-center">
                <img src="assets/image/waffles_rebuild.png" 
                    alt="Waffles rebuild"
                    class="img-fluid"
                />
            </p>
            <i>
                Porciones: 2<br/>
                Tiempo de preparación: 10 minutos<br/>
                Tiempo de Cocción: 5 minutos<br/>
            </i>
            <br>
            <h5>Ingredientes</h5>
            <ul>
                <li>1 huevo</li>
                <li>1 pizca de sal</li>
                <li>2 cucharadas medidoras rebuild strength</li>
                <li>2 cucharadas harina</li>
                <li>1/4 cucharada polvo de hornear</li>
                <li>1 cucharada aceite de canola</li>
                <li>5 cucharadas leche descremada*</li>
            </ul>
            <h5>Método de preparación:</h5>
            <p>
                *Sólo si eres tolerante. La Leche Descremada puede 
                sustituirse por Leche de Soya o agua con Proteína en 
                Polvo Herbalife Nutrition. Los valores nutricionales 
                son referenciales.
            </p>
            <h5>PREPARACIÓN</h5>
            <p>
                Rociar la Wafflera con aerosol para bandejas y precalentar. 
                Mezclar en un recipiente el Rebuild Strenght, la harina, el 
                polvo de hornear y la sal con un batidor de mano. Agregar el 
                huevo, el aceite y la leche. Mezclar bien. Verter sobre la 
                Wafflera y dejar cocinar. Decorar con tus frutas favoritas, 
                si se desea, antes de servir.
            </p>
            <h5>Nutrientes por porción</h5>
            <table class="table">
                <th></th>
                <th>Calorías (kcal)</th>
                <th>Grasas (g)</th>
                <th>Carbohidratos (g)</th>
                <th>Proteína (g)</th>
                <tr></tr>
                <td>Por porción</td>
                <td>280 cal.</td>
                <td>11 g.</td>
                <td>26,5 g.</td>
                <td>19 g.</td>
            </table>
            <hr>
            <i>Valores por porción. Rinde dos porciones. </i>
        </div>
    </div>
</div>
