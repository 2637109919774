import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css']
})
export class ProductosComponent implements OnInit {

  batidos: string;
  fibra: string;
  aloe: string;

  sportActivator: string;
  cr7: string;
  herbalife24: string;

  beverage: string;
  pdm: string;
  ppp: string;

  herbalifeLine: string;
  multivitaminico: string;
  nrg: string;
  teOriginal: string;
  teFrambueza: string;
  teChai: string;
  teDurazno: string;
  teLimon: string;
  nutriSoup: string;
  xtraCal: string;
  collage: string;

  skin765: string;
  skin766: string;
  skin767: string;
  skin768: string;
  skin770: string;
  skin771: string;
  skin772: string;
  skin773: string;
  skin774: string;
  skin899: string;
  skin3293: string;
  skin3294: string;
  skin3295: string;
  herbalAloe: string;


  constructor() {
    this.batidos = '31.900';
    this.fibra = '31.200';
    this.aloe = '33.900';
    this.teFrambueza = '25.500';
    this.teChai = '25.5000';
    this.teDurazno = '25.500';
    this.teLimon = '25.500';
    this.sportActivator = '30.800';
    this.cr7 = '31.200';
    this.herbalife24 = '65.000';

    this.beverage = '29.000';
    this.pdm = '41.900';
    this.ppp = '26.100';

    this.herbalifeLine = '35.500';
    this.multivitaminico = '14.500';
    this.nrg = '20.300';
    this.teOriginal = '44.700';

    this.nutriSoup = '31.900';
    this.xtraCal = '14.800';
    this.collage = '35.800';

    this.skin765 = '22.700';
    this.skin766 = '22.700';
    this.skin767 = '17.200';
    this.skin768 = '51.200';
    this.skin770 = '35.800';
    this.skin771 = '35.800';
    this.skin772 = '17.800';
    this.skin773 = '19.300';
    this.skin774 = '38.500';
    this.skin899 = '38.500';
    this.skin3293 = '78.400';
    this.skin3294 = '78.400';
    this.skin3295 = '129.600';
    this.herbalAloe = '11.500';
  }

  ngOnInit(): void {
  }

}
