import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deportista',
  templateUrl: './deportista.component.html',
  styleUrls: ['./deportista.component.css']
})
export class DeportistaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
