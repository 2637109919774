import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aloes',
  templateUrl: './aloes.component.html',
  styleUrls: ['./aloes.component.css']
})
export class AloesComponent implements OnInit {
  aloes: string;

  constructor() {
    this.aloes = '33.900';
  }

  ngOnInit(): void {
  }

}
