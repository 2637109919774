<div class="box-oportunidad">
    <img src="assets/image/happy-people-pointing-at-you.png" 
        alt="Oportunidad de Negocio"
        class="img-fluid"
    />
    <div class="title-oportunidad">OPORTUNIDAD DE NEGOCIO</div>
</div>
<div class="container col-sm-10">
    <div class="row">
        <div class="col-sm box-comienza">
            <h1 class="text-center lh-lg box-title-comienza">
                Comienza ¡¡¡AHORA!!! como cliente Herbalife, con un 25% de Descuento<br/>
                Ventajas, Promociones, Descuentos y Pack especiales por ser cliente Herbalife en Chile
            </h1>

        </div>
    </div>
    <div class="row">
        <h1 class="box-title-cliente-vip m-auto">
            ¿Por qué Herbalife Nutrition?
        </h1>
    </div>
    <div class="row">
        <div class="col-sm box-cliente-vip">
            <p class="text-center">
                <img src="assets/image/man-with-glasses.png" 
                    alt="Man With Glasses"
                    class="img-fluid"
                />
                CONSTRUYE TU PROPIO NEGOCIO<br/><br/>
                Ábrete paso a un mundo de oportunidades. Si ayudar a los 
                demás y obtener ingresos extras es algo en lo que estarías 
                interesado, entonces una gran oportunidad de negocio 
                independiente espera por ti.

            </p>
        </div>
        <div class="col-sm box-cliente-vip">
            <p class="text-center">
                <img src="assets/image/man-and-woman-training.png" 
                    alt="Man and Woman Training"
                    class="img-fluid"
                />
                CONVIÉRTETE EN DISTRIBUIDOR INDEPENDIENTE<br/><br/>
                Nuestra oportunidad de negocio es flexible. Decide tus 
                horas y trabaja desde cualquier lugar. Construye tu equipo 
                y crea un negocio que encaje en tu vida.
            </p>
        </div>
        <div class="col-sm box-cliente-vip">
            <p class="text-center">
                <img src="assets/image/people-sitting-arround-table-looking-at-a-tablet.png" 
                    alt="People sitting arround table looking at a tablet"
                    class="img-fluid"
                />
                CONSTRUYE TU PROPIO NEGOCIO INDEPENDIENTE ALREDEDOR DE TU PASIÓN<br/><br/>
                Sé un coach, un mentor y un amigo. Ayuda a los clientes a 
                romper viejos hábitos, mejorar su nutrición y vivir una 
                vida más saludable y activa.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-sm mt-3">
            <p class="text-center">
                <img src="assets/image/kit-registro.png" 
                    alt="Kit de registro Chile"
                    class="img-fluid"
                />
            </p>
            <h1 class="text-center pt-5">
                COMENZAR ES SIMPLE
            </h1>
            <div class="offset-sm-4 box-comienza-negocio mt-5">
                <p class="text-center">
                    COMIENZA TU NEGOCIO Y RECIBE TU KIT DE INICIO, 
                    QUE TE DA LAS HERRAMIENTAS ESENCIALES Y EL ENTRENAMIENTO 
                    PARA PONERTE EN MARCHA.<br/>
                </p>
                <div class="mt-1" style="padding-left: 200px; margin-bottom: 20px;">
                    <i class="precio" style="margin:20px">${{ ibp }}.-</i>
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="DGNDR66J9G44S">
                        <input type="image" src="https://www.paypalobjects.com/es_ES/ES/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 offset-sm-2 box-ticket">
            <img src="assets/image/greentick.png" 
                alt="ticket verde" 
                width="80"
                class="img-ticket img-fluid"
            />
            <p>
                Mochila de Nutrición Herbalife Nutrition
            </p>
        </div>
        <div class="col-sm-4 box-ticket">
            <img src="assets/image/greentick.png" 
                alt="ticket verde" 
                width="80"
                class="img-ticket img-fluid"
            />
            <p>
                Fórmula 1: Batido Nutricional Naranja Crema
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 offset-sm-2 box-ticket">
            <img src="assets/image/greentick.png" 
                alt="ticket verde" 
                width="80"
                class="img-ticket img-fluid"
            />
            <p>
                Catálogo de Nutrición de Herbalife Nutrition 
            </p>
        </div>
        <div class="col-sm-4 box-ticket">
            <img src="assets/image/greentick.png" 
                alt="ticket verde" 
                width="80"
                class="img-ticket img-fluid"
            />
            <p>
                Shaker Herbalife Nutrition
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 offset-sm-2 box-ticket">
            <img src="assets/image/greentick.png" 
                alt="ticket verde" 
                width="80"
                class="img-ticket img-fluid"
            />
            <p>
                GoHerbalife - Tu tienda de nutrición gratuita de Herbalife Nutrition en línea
            </p>
        </div>
        <div class="col-sm-4 box-ticket">
            <img src="assets/image/greentick.png" 
                alt="ticket verde" 
                width="80"
                class="img-ticket img-fluid"
            />
            <p>
                Acceso a las herramientas y a la formación en línea
            </p>
            
        </div>
        
    </div>
    <div class="row">
        <div class="col-sm box-unico">
            <h1 class="text-center">OPORTUNIDAD ÚNICA </h1>
        </div>
    </div>
    <div class="row">
        <div class="col-sm box-unico">
            <p class="text-center">
                <img src="assets/image/man-explaining-at-a-board.png" 
                    alt="Man explaining at a board"
                    class="img-fluid"
                />
            </p>
            <p class="text-center">
                LA DIFERENCIA DEL DISTRIBUIDOR<br/><br/>
                A pesar de ser un negocio independiente, nunca estarás 
                solo ya que contarás con un sistema de entrenamiento y 
                formación continuo, además, serás parte de una comunidad 
                de Distribuidores Independientes que estarán a tu lado 
                asesorándote con su experiencia en el negocio.

            </p>
        </div>
        <div class="col-sm box-unico">
            <p class="text-center">
                <img src="assets/image/man-explaining-at-a-board.png" 
                    alt="Man explaining at a board"
                    class="img-fluid"
                />
            </p>
            <p class="text-center">
                ACCESO EXCLUSIVO A NUESTROS PRODUCTOS<br/><br/>
                Nuestros productos no están disponibles en tiendas o 
                supermercados. Se venden exclusivamente a través de una 
                red de Distribuidores Independientes de Herbalife Nutrition, 
                que se benefician de un amplio entrenamiento y educación.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-sm box-unico">
            <h1 class="text-center">PREGUNTAS FRECUENTES </h1>
        </div>
    </div>
    <div class="row">
        <div class="col-sm box-faq">
            <p><strong> P: ¿Cuánto tiempo tendré que dedicarle?</strong></p>
            <p> 
                Tú decides cuánto tiempo y esfuerzo invertir en la 
                Oportunidad de Negocio de Nutrición de Herbalife. 
                Si quieres dedicar 40 o 4 horas a la semana - o nada de 
                tiempo - depende de ti.
            </p>
        </div>
        <div class="col-sm box-faq">
            <p><strong>P: ¿Cómo puedo aprender sobre nutrición?</strong></p>
            <p>
                Al convertirte en Distribuidor Independiente de Herbalife 
                Nutrition, tendrás acceso a una educación integral y a 
                herramientas de capacitación que te ayudarán a aprender 
                sobre nutrición, bienestar, técnicas de venta y mucho más.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-sm box-faq">
            <p><strong>P: ¿Cuánto ganaré? </strong></p>
            <p> 
                Depende de ti. pero siempre establece expectativas 
                realistas. Tanto si eliges trabajar a tiempo parcial 
                como a tiempo completo, la cantidad de horas, la energía 
                y la dedicación que pongas en tu negocio decidirán tus 
                resultados. Antes de tomar una decisión, por favor lee 
                nuestra <a href="/assets/pdf/declaracion-de-ganancias.pdf"> 
                    Declaración de Ganancias</a> Brutas Promedio.
            </p>
        </div>
        <div class="col-sm box-faq">
            <p><strong>P: ¿Puedo vender en línea?</strong></p>
            <p>
                Con GoHerbalife puedes tener tu propio sitio web sin la 
                molestia y el costo de crear el tuyo. Es fácil construir 
                y ayudar a apoyar tu negocio y las necesidades de tus 
                clientes.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-sm box-faq">
            <p><strong>P: ¿Cuáles son los principales beneficios? </strong></p>
            <p> <strong>Flexibilidad</strong><br/> 
                Decide tus horas y trabaja desde donde quieras, una 
                oportunidad de negocio que encaje en tu vida.<br/><br/>
                <strong>Calidad</strong> <br/>
                Ofrecemos los mejores productos respaldados por la ciencia 
                y utilizados por millones de clientes cada día.<br/><br/>
                <strong>Comunidad </strong><br/>
                Al unirse a la Oportunidad de Negocio de Nutrición de 
                Herbalife, te unes a una comunidad global establecida 
                -obtén apoyo y asesoramiento y sé parte de la familia de 
                Herbalife Nutrition.<br/><br/>
                <strong>Recompensas </strong><br/>
                Encontrarás ofertas y descuentos, y todos los últimos 
                materiales educativos y de entrenamiento para ayudar a 
                respaldar sus conocimientos sobre productos y nutrición.
            </p>
        </div>
        <div class="col-sm box-faq">
            <p><strong>P: ¿Cuánto dinero debo gastar mensualmente?</strong><br/><br/>
                <strong>No tienes que gastar nada si no quieres.</strong> 
                En Herbalife no te exigimos un monto mínimo de compra ni mensual 
                ni anualmente. Compras cuando te apetece. 
            </p>
            <hr/>
            <p><strong>P: ¿Compraré directamente a Herbalife?</strong><br/><br/>
                <strong>Así es. </strong> Tus pedidos serán tramitados por la central de 
                Herbalife y te lo enviará directamente a tu casa, Sin intermediarios. <br/>
                Además podrás realizar tus pedidos desde el móvil con la app oficial 
                <strong>Herbalife Tienda </strong> para Android o iPhone de uso exclusivo
                para clientes y miembros de Herbalife.
            </p>
            <hr/>
            <p><strong>P: ¿Puedo vender si me hago cliente?</strong><br/><br/>
                <strong>¡Por supuesto!</strong> Al ser cliente se te concederá la misma
                licencia que tiene un distribuidor de Herbalife. Si quieres más información 
                sobre como vender Herbalife. Contactanos al <i class="contacto">Email </i>                  
            </p>
        </div>
    </div>
</div>