import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-formas-pago',
  templateUrl: './formas-pago.component.html',
  styleUrls: ['./formas-pago.component.css']
})
export class FormasPagoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
