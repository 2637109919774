import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desayuno',
  templateUrl: './desayuno.component.html',
  styleUrls: ['./desayuno.component.css']
})
export class DesayunoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
