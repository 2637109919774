import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fibra',
  templateUrl: './fibra.component.html',
  styleUrls: ['./fibra.component.css']
})
export class FibraComponent implements OnInit {

  fibra: string;

  constructor() {
    this.fibra = '31.200';
  }

  ngOnInit(): void {
  }

}
