import { ModuleWithProviders } from '@angular/compiler/src/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { ProductosComponent } from './components/productos/productos.component';
import { BatidosComponent } from './components/batidos/batidos.component';
import { ProteinasComponent } from './components/proteinas/proteinas.component';
import { FibraComponent } from './components/fibra/fibra.component';
import { SuplementosComponent } from './components/suplementos/suplementos.component';
import { CremasComponent } from './components/cremas/cremas.component';
import { Nutricion24Component } from './components/nutricion24/nutricion24.component';
import { EmbajadoresComponent } from './components/embajadores/embajadores.component';
import { DesayunoComponent } from './components/desayuno/desayuno.component';
import { DeportistaComponent } from './components/deportista/deportista.component';
import { PielComponent } from './components/piel/piel.component';
import { ArticulosComponent } from './components/articulos/articulos.component';
import { DetalledietaComponent } from './components/articulos/detalledieta/detalledieta.component';
import { DetalleejerciciosComponent } from './components/articulos/detalleejercicios/detalleejercicios.component';
import { RecetasComponent } from './components/recetas/recetas.component';
import { FavoritaComponent } from './components/favorita/favorita.component';
import { NegocioComponent } from './components/negocio/negocio.component';
import { DistribuidorComponent } from './components/distribuidor/distribuidor.component';
import { ClientevipComponent } from './components/clientevip/clientevip.component';
import { SuccessComponent } from './components/success/success.component';
import { CancelComponent } from './components/cancel/cancel.component';
import { AloesComponent } from './components/aloes/aloes.component';
import { TesComponent } from './components/tes/tes.component';
import { DetalleCremaZapalloComponent } from './components/recetas/detalle-crema-zapallo/detalle-crema-zapallo.component';
import { DetalleFrutosRojosComponent } from './components/recetas/detalle-frutos-rojos/detalle-frutos-rojos.component';
import { DetalleAderezoMangoComponent } from './components/recetas/detalle-aderezo-mango/detalle-aderezo-mango.component';
import { DetalleWafflesComponent } from './components/recetas/detalle-waffles/detalle-waffles.component';
import { FormasPagoComponent } from './components/formas-pago/formas-pago.component';
import { ErrorComponent } from './components/error/error.component';

const appRoutes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'home', component: HomeComponent},
    {path: 'productos', component: ProductosComponent},
    {path: 'batidos', component: BatidosComponent},
    {path: 'proteinas', component: ProteinasComponent},
    {path: 'suplementos', component: SuplementosComponent},
    {path: 'fibra', component: FibraComponent},
    {path: 'cremas', component: CremasComponent},
    {path: 'nutricion24', component: Nutricion24Component},
    {path: 'embajadores', component: EmbajadoresComponent},
    {path: 'desayuno', component: DesayunoComponent},
    {path: 'deportista', component: DeportistaComponent},
    {path: 'piel', component: PielComponent},
    {path: 'articulos', component: ArticulosComponent},
    {path: 'detalledieta', component: DetalledietaComponent},
    {path: 'detalleejercicios', component: DetalleejerciciosComponent},
    {path: 'recetas', component: RecetasComponent},
    {path: 'favorita', component: FavoritaComponent},
    {path: 'negocio', component: NegocioComponent},
    {path: 'distribuidor', component: DistribuidorComponent},
    {path: 'clientevip', component: ClientevipComponent},
    {path: 'cancel', component: CancelComponent},
    {path: 'success', component: SuccessComponent},
    {path: 'tes', component: TesComponent},
    {path: 'aloes', component: AloesComponent},
    {path: 'detalleCremaZapallo', component: DetalleCremaZapalloComponent},
    {path: 'detalleWaffles', component: DetalleWafflesComponent},
    {path: 'detalleFrutosRojos', component: DetalleFrutosRojosComponent},
    {path: 'detalleAderezoMango', component: DetalleAderezoMangoComponent},
    {path: 'formasPago', component: FormasPagoComponent},
    {path: '**', component: ErrorComponent},
];


// Exportar el módulo de rutas
export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
