<nav class="navbar fixed-top navbar-expand-lg navbar-light navbar-mip">
    <a class="navbar-brand" href="/">
        <img src="assets/image/logo.png" alt="Logo Herbalife Mipesoideal"/>
        <br/>
        <span class="texto-logo">Asociado Independiente Herbalife</span>
    </a>
    <button class="navbar-toggler" type="button" 
        data-toggle="collapse" data-target="#navbarNavDropdown" 
        aria-controls="navbarNavDropdown" aria-expanded="false" 
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav ml-auto nav-lg">
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/home']" 
                    [routerLinkActive]="['active']">Inicio 
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/batidos']" 
                [routerLinkActive]="['active']">Batidos</a>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" 
                    [routerLink]="['/productos']" 
                    [routerLinkActive]="['active']"
                    id="navbarDropdownMenuLink" data-toggle="dropdown" 
                    aria-haspopup="true" aria-expanded="false">
                    Productos
                </a>
                <div class="dropdown-menu dropdown-mip" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item item-mip" [routerLink]="['/proteinas']" 
                        [routerLinkActive]="['active']">Proteinas
                    </a>
                    <a class="dropdown-item item-mip" [routerLink]="['/suplementos']" 
                        [routerLinkActive]="['active']">Suplementos
                    </a>
                    <a class="dropdown-item item-mip" [routerLink]="['/fibra']" 
                        [routerLinkActive]="['active']">Fibra
                    </a>
                    <a class="dropdown-item item-mip" [routerLink]="['/nutricion24']" 
                        [routerLinkActive]="['active']">Nutrición Deportivas
                    </a>
                    <a class="dropdown-item item-mip" [routerLink]="['/tes']" 
                        [routerLinkActive]="['active']">Tés
                    </a>
                    <a class="dropdown-item item-mip" [routerLink]="['/aloes']" 
                        [routerLinkActive]="['active']">Aloe Vera
                    </a>
                    <a class="dropdown-item item-mip" [routerLink]="['/cremas']" 
                        [routerLinkActive]="['active']">Cremas
                    </a>
                </div>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" 
                    id="navbarDropdownMenuLink" data-toggle="dropdown" 
                    aria-haspopup="true" aria-expanded="false">
                    Logra tus Objetivos
                </a>
                <div class="dropdown-menu dropdown-mip" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item item-mip" [routerLink]="['/desayuno']" 
                        [routerLinkActive]="['active']">Desayuno Ideal
                    </a>
                    <a class="dropdown-item item-mip" [routerLink]="['/deportista']" 
                        [routerLinkActive]="['active']">Nutrición Deportiva
                    </a>
                    <a class="dropdown-item item-mip" [routerLink]="['/piel']" 
                        [routerLinkActive]="['active']">Nutre tu Piel
                    </a>
                </div>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" 
                    id="navbarDropdownMenuLink" data-toggle="dropdown" 
                    aria-haspopup="true" aria-expanded="false">
                    Tips Nutrición y Vida Sana
                </a>
                <div class="dropdown-menu dropdown-mip" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item item-mip" [routerLink]="['/articulos']" 
                        [routerLinkActive]="['active']">Articulos
                    </a>
                    <a class="dropdown-item item-mip" [routerLink]="['/recetas']" 
                        [routerLinkActive]="['active']">Recetas
                    </a>
                    <a class="dropdown-item item-mip" [routerLink]="['/favorita']" 
                        [routerLinkActive]="['active']">Mi Nutrición Favorita
                    </a>
                </div>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" 
                    id="navbarDropdownMenuLink" data-toggle="dropdown" 
                    aria-haspopup="true" aria-expanded="false">
                    Oportunidad de Negocio
                </a>
                <div class="dropdown-menu dropdown-mip" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item item-mip" [routerLink]="['/negocio']" 
                        [routerLinkActive]="['active']">Construye tu Propio Negocio
                    </a>
                    <a class="dropdown-item item-mip" [routerLink]="['/distribuidor']" 
                        [routerLinkActive]="['active']">Conviertete en Distribuidor Independiente
                    </a>
                    <a class="dropdown-item item-mip" [routerLink]="['/clientevip']" 
                        [routerLinkActive]="['active']">Quiero comenzar ¡¡Ahora!!
                    </a>
                </div>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/embajadores']" [routerLinkActive]="['active']">Embajadores</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" 
                    [routerLink]="['/formasPago']"
                    [routerLinkActive]="['active']"
                >Formas de Pago</a>
            </li>
        </ul>
    </div>
</nav>

