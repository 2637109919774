<div class="container mt-3 text-center">
    <h1 class="text-center">Cremas Herbalife</h1>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm box-cremas">
            <div class="box-cremas-header text-center">SKIN Limpiador Relajante de Sábila 150 ml</div>
            <div class="box-cremas-body">
                <h5 class="box-cremas-title">Limpiador Relajante de Aloe</h5>
                <h6 class="box-cremas-subtitle">Código 0765 </h6>
                <p class="text-center"> 
                    <img src="assets/image/serum_reductor.png" 
                        alt="Limpiador Relajante de Aloe"
                        style="width: 80px;"
                    /> 
                </p>
                <p class="precio text-center">${{ skin765 }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>
                        Limpiador a base de aloe con fragancia fresca y suave. 
                        Perfecto para la piel normal o seca.
                    </li>
                    <li>
                        Espuma sedosa y suave que elimina el exceso de grasa, 
                        suciedad y quita el maquillaje ligero de la piel, sin irritarla.
                    </li>
                    <li>
                        La sábila suaviza e hidrata la piel. Sin adición de parabenos, ni sulfatos.
                    </li>
                    <li>
                        Probado dermatológicamente.
                    </li>
                </ul>
                <!-- <div class="box-cremas-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="W7957GSEU3698">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>

        <div class="col-sm box-cremas">
            <div class="box-cremas-header text-center">SKIN Limpiador Cítrico para la Piel 150 ml</div>
            <div class="box-cremas-body">
                <h5 class="box-cremas-title">Limpiador Cítrico</h5>
                <h6 class="box-cremas-subtitle">Código 0766 </h6>
                
                <p class="text-center"> 
                    <img src="assets/image/limpiador_citrico.png" 
                        alt="Limpiador Cítrico"
                        style="width: 110px;"
                    /> 
                </p>
                <p class="precio text-center">${{ skin766 }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>
                        Limpiador con toques de naranja y pomelo, 
                        con semillas de jojoba. Perfecto para la piel normal a grasosa.
                    </li>
                    <li>
                        Probado clínicamente para mostrar una reducción en el sebo de la 
                        piel después de un solo uso.*
                    </li>
                    <li>
                        Fórmula con microperlas revitalizantes.
                    </li>
                    <li>
                        Sin adición de parabenos, ni sulfatos.
                    </li>
                    <li>
                        Probado dermatológicamente.
                    </li>
                </ul>
                <!-- <div class="box-cremas-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="6RB9V6XVBMHDQ">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>

        <div class="col-sm box-cremas">
            <div class="box-cremas-header text-center">SKIN Serum Reductor de Líneas 30 ml</div>
            <div class="box-cremas-body">
                <h5 class="box-cremas-title">Serum Reductor</h5>
                <h6 class="box-cremas-subtitle">Código 0768 </h6>
                
                <p class="text-center"> 
                    <img src="assets/image/serum_reductor.png" 
                        alt="H24 - Rebuild Strength"
                        style="width: 80px;"
                    /> 
                </p>
                <p class="precio text-center">${{ skin768 }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>
                        El uso de día y de noche ayuda a maximizar sus resultados.
                    </li>
                    <li>
                        Reduce la apariencia de líneas finas y arrugas.
                    </li>
                    <li>
                        Probado clínicamente para mostrar una mejora extraordinaria 
                        en la suavidad, tersura, resplandor, fulgor y luminosidad 
                        de la piel en solo siete días.*
                    </li>
                    <li>
                        Para todo tipo de piel.
                    </li>
                    <li>
                        Sin adición de parabenos.
                    </li>
                    <li>
                        Probado dermatológicamente.
                    </li>
                    <p></p>
                    <li>
                        * Probado en personas para comprobar suavidad, 
                        tersura, resplandor, fulgor y luminosidad mediante 
                        clasificación experta en intervalos de dos, cuatro 
                        y siete días. El 100% de personas mostró una mejora 
                        en las cinco áreas en siete días.
                    </li>
                </ul>
                <!-- <div class="box-cremas-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="HGB723ZRET3NW">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>

        <div class="col-sm box-cremas">
            <div class="box-cremas-header text-center">SKIN Gel Reafirmante para Contorno de Ojos 15 ml</div>
            <div class="box-cremas-body">
                <h5 class="box-cremas-title">SKIN Gel Reafirmante </h5>
                <h6 class="box-cremas-subtitle">Código 0770 </h6>
                <p class="text-center"> 
                    <img src="assets/image/gel_contorno_de_ojos.png" 
                        alt="CR7 DRIVE"
                        style="width: 100px;"
                    /> 
                </p>
                <p class="precio text-center">${{ skin770 }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>
                        Ayuda a mejorar la apariencia de firmeza de 
                        la piel alrededor de los ojos.*
                    </li>
                    <li>
                        Mezcla de carbohidratos que ayudan a mejorar la resistencia.
                    </li>
                    <li>
                        Contiene extracto revitalizante de pepino.
                    </li>
                    <li>
                        Para todo tipo de piel.
                    </li>
                    <li>
                        Probado oftalmológicamente.
                    </li>
                    <li>
                        Sin adición de parabenos.
                    </li>
                    <li>
                        Probado dermatológicamente.
                    </li>
                    <p></p>
                    <li>
                        *Probado en sujetos para comprobar firmeza y elasticidad 
                        mediante clasificación visual experta en intervalos de 
                        siete y 42 días. Un 85% de los sujetos mostró una mejora 
                        en la firmeza /elasticidad de la piel debajo de los ojos 
                        a los 42 días.
                    </li>
                </ul>
                <!-- <div class="box-cremas-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="2PB8BL6RF3FYU">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm box-cremas">
            <div class="box-cremas-header text-center">SKIN Crema Hidratante para Contorno de Ojos 15 ml</div>
            <div class="box-cremas-body">
                <h5 class="box-cremas-title">SKIN Crema Hidratante.</h5>
                <h6 class="box-cremas-subtitle">Código 0771 </h6>
                <p class="image-h24 text-center"> 
                    <img src="assets/image/crema_hidratante_contorno_de_ojos.png" 
                        alt="Sport Activator"
                        style="width: 100px;"> 
                </p>
                <p class="precio text-center">${{ skin771 }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>
                        El uso de día y de noche ayuda a maximizar sus resultados.
                    </li>
                    <li>
                        Reduce la apariencia de líneas finas y arrugas.
                    </li>
                    <li>
                        Probado clínicamente para mostrar una mejora extraordinaria 
                        en la suavidad, tersura, resplandor, fulgor y luminosidad de 
                        la piel en solo siete días.*
                    </li>
                    <li>Para todo tipo de piel.</li>
                    <li>Sin adición de parabenos.</li>
                    <li>Probado dermatológicamente.</li>
                    <p></p>
                    <li>
                        *Probado en personas para comprobar suavidad, tersura, 
                        resplandor, fulgor y luminosidad mediante clasificación 
                        experta en intervalos de dos, cuatro y siete días. El 100% 
                        de personas mostró una mejora en las cinco áreas en siete 
                        días.
                    </li>
                </ul>
                <!-- <div class="box-cremas-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="5PV2R86T6R7A2">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>

        <div class="col-sm box-cremas">
            <div class="box-cremas-header text-center">SKIN Exfoliante Instantáneo de Arándanos 120 ml</div>
            <div class="box-cremas-body">
                <h5 class="box-cremas-title">Exfoliante Instantaneo con Arandanos </h5>
                <h6 class="box-cremas-subtitle">Código 0772 </h6>
                <p class="text-center"> 
                    <img src="assets/image/exfoliante_arandanos.png" 
                        alt="SKIN Exfoliante Instantáneo de Arándanos"
                        style="width: 130px;"
                    /> 
                </p>
                <p class="precio text-center">${{ skin772 }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>
                        Revela una piel de aspecto más saludable.
                    </li>
                    <li>
                        Fragancia fresca y frutal que aviva los sentidos.
                    </li>
                    <li>
                        Para todo tipo de piel.
                    </li>
                    <li>
                        Sin adición de parabenos, ni sulfatos.
                    </li>
                    <li>
                        Probado dermatológicamente.
                    </li>
                </ul>
                <!-- <div class="box-cremas-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="PPSJHZDFUVQZJ">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>

        <div class="col-sm box-cremas">
            <div class="box-cremas-header text-center">SKIN Mascarilla Purificadora de Arcilla con Menta 120 ml</div>
            <div class="box-cremas-body">
                <h5 class="box-cremas-title">Mascarilla Purificadora de Arcilla con Menta </h5>
                <h6 class="box-cremas-subtitle">Código 0773 </h6>
                <p class="text-center"> 
                    <img src="assets/image/exfoliante_arandanos.png" 
                        alt="Mascarilla Purificadora de Arcilla con Menta"
                        style="width: 130px;"
                    /> 
                </p>
                <p class="precio text-center">${{ skin773 }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>
                        Probado clínicamente para mejorar el aspecto de los poros en un solo uso.*
                    </li>
                    <li>
                        Un toque de menta verde y romero estimula sus sentidos mientras deja su piel 
                        más suave y tersa.
                    </li>
                    <li>
                        Para todo tipo de piel.
                    </li>
                    <li>
                        Sin adición de parabenos, ni sulfatos.
                    </li>
                    <li>
                        Probado dermatológicamente.
                    </li>
                </ul>
                <!-- <div class="box-cremas-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="R3JN4ZQPE3HMS">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>
        
        <div class="col-sm box-cremas">
            <div class="box-cremas-header text-center">SKIN Crema Renovadora de Noche 30 ml</div>
            <div class="box-cremas-body">
                <h5 class="box-cremas-title">SKIN Crema Renovadora.</h5>
                <h6 class="box-cremas-subtitle">Código 0774 </h6>
                <p class="image-h24 text-center"> 
                    <img src="assets/image/crema_renovadora_de_noche.png" 
                        alt="Sport Activator"
                        style="width: 80px;"
                    /> 
                </p>
                <p class="precio text-center">${{ skin774 }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>
                        Probado clínicamente para mostrar que la 
                        piel se ve más tersa y se siente más suave en solo siete días.
                    </li>
                    <li>
                        Brinda una mejora notable del aspecto de la piel en siete días.*
                    </li>
                    <li>
                        Probado clínicamente para mostrar que la hidratación de la piel 
                        se duplica por ocho horas.**
                    </li>
                    <li>
                        Sin adición de parabenos.
                    </li>
                    <li>
                        Probado dermatológicamente.
                    </li>
                    <p></p>
                    <li>
                        *Probado en personas para comprobar suavidad, 
                        tersura, luminosidad, fulgor y resplandor 
                        mediante clasificación visual experta en 
                        intervalos de dos, cuatro y siete días. 
                        El 100% de las personas mostró una mejora 
                        en la tersura, suavidad y fulgor en siete días. 
                        Un 97% de las personas mostró una mejora en el 
                        resplandor y luminosidad.
                    </li>
                    <li>
                        **Probado en personas midiendo los niveles de hidratación 
                        de la piel en intervalos de ocho horas. La piel del 100% 
                        de las personas mostró una duplicación de los niveles de 
                        hidratación por encima del nivel base después de ocho horas.
                    </li>
                </ul>
                <!-- <div class="box-cremas-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="FKA9QTB85TU2N">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm box-cremas">
            <div class="box-cremas-header text-center">Herbal Aloe Jabón Líquido Manos y Cuerpo 250 ml</div>
            <div class="box-cremas-body">
                <h5 class="box-cremas-title">Jabón liquido Manos y Cuerpo HERBAL ALOE </h5>
                <h6 class="box-cremas-subtitle">Código 2561 </h6>
                <p class="text-center"> 
                    <img src="assets/image/jabon_liquido.png" 
                        alt="CR7 DRIVE"
                        style="width: 100px;"
                    /> 
                </p>
                <p class="precio text-center">${{ herbalAloe }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>
                        Humecta y suaviza.
                    </li>
                    <li>
                        Limpia y refresca.
                    </li>
                    <li>
                        Para todo tipo de piel, todos los días.
                    </li>
                    <li>
                        Libre de sulfatos y parabenos con ingrediente botánicos.
                    </li>
                    <li>
                        Su fórmula a base de aloe vera y aceite de oliva es ideal 
                        para una limpieza suave e hidratación profunda de la piel; 
                        mientras usted disfruta de un baño reconfortante.
                    </li>
                    <p></p>
                    <li>
                        * Uso externo únicamente. Evitar contacto con los ojos, 
                        en caso de contacto con los ojos enjuague con bastante agua. 
                        Si observa alguna reacción desfavorable suspenda su uso. 
                        En caso persista, consulte a su médico. Mantenga fuera del 
                        alcance de los niños.
                    </li>
                </ul>
                <!-- <div class="box-cremas-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="PEJYQUWP85EZ2">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>

        <div class="col-sm box-cremas">
            <div class="box-cremas-header text-center">Herbal Aloe Gel Refrescante Corporal 200 ml</div>
            <div class="box-cremas-body">
                <h5 class="box-cremas-title">Gel Refrescante corporal Herbal Aloe</h5>
                <h6 class="box-cremas-subtitle">Código 2562 </h6>
                <p class="text-center"> 
                    <img src="assets/image/gel_refrescante.png" 
                        alt="Gel Refrescante corporal Herbal Aloe"
                        style="width: 120px;"
                    /> 
                </p>
                <p class="precio text-center">${{ herbalAloe }}.-</p>                
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>
                        De uso diario para todo tipo de piel.
                    </li>
                    <li>
                        Resultados de suavidad y tersura clínicamente mostrados.**
                    </li>
                    <li>
                        Libre de parabenos.
                    </li>
                    <li>
                        Brinda humectación prolongada con extractos botánicos acondicionadores de la piel.
                    </li>
                    <li>
                        Hidrata, suaviza y refresca su piel con esta textura ligera tipo gel y libre de fragancia.
                    </li>
                    <li>
                        Contiene aloe vera y extractos botánicos acondicionadores que cuidarán su piel y la de toda su familia.
                    </li>
                    <li>
                        Aplique diariamente sobre la piel según sea necesario.
                    </li>
                    <p></p>
                    <li>
                        * Uso externo únicamente. Evitar contacto con los ojos, en caso de contacto 
                        con los ojos enjuague con bastante agua. Si observa alguna reacción desfavorable 
                        suspenda su uso. En caso persista, consulte a su médico. Mantenga fuera 
                        del alcance de los niños.
                    </li>
                    <li>
                        ** Afirmaciones respaldadas por un panel de prueba individual.
                    </li>
                </ul>
                <!-- <div class="box-cremas-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="BLJ2A9UQT49DE">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>

        <div class="col-sm box-cremas">
            <div class="box-cremas-header text-center">Herbal Aloe Crema para Manos y Cuerpo 200 ml</div>
            <div class="box-cremas-body">
                <h5 class="box-cremas-title">Herbal Aloe crema para Manos y Cuerpo</h5>
                <h6 class="box-cremas-subtitle">Código 2563 </h6>
                <p class="image-h24 text-center"> 
                    <img src="assets/image/acondicionador_fortificante.png" 
                        alt="Herbal Aloe crema para Manos y Cuerpo"
                        style="width: 100px;"> 
                </p>
                <p class="precio text-center">${{ herbalAloe }}.-</p>                
                <h5 class="text-center">Descripción general</h5>
                <p>Shampoo y acondicionador fortificante Herbal Aloe</p>
                <ul class="text-left">Beneficios:
                    <li>
                        De uso diario, para todo tipo de piel.
                    </li>
                    <li>
                        Libre de parabenos. Con ingredientes botánicos.
                    </li>
                    <li>
                        Atrapa la humedad en su piel gracias a los humectantes de tripleacción y rápida absorción.
                    </li>
                    <li>
                        Crema ligera a base de sábila y manteca de karité, que deja su piel suave y tersa.
                    </li>
                    <li>
                        Aplicar según sea necesario.
                    </li>
                </ul>
                <!-- <div class="box-cremas-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="6AG7MX24Q9DAN">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>        

        <div class="col-sm box-cremas">
            <div class="box-cremas-header text-center">Herbal Aloe Shampoo Fortificante 250 ml</div>
            <div class="box-cremas-body">
                <h5 class="box-cremas-title">Shampoo y acondicionador fortificante Herbal Aloe.</h5>
                <h6 class="box-cremas-subtitle">Código 2564 </h6>
                <p class="image-h24 text-center"> 
                    <img src="assets/image/shampoo_fortificante.png" 
                        alt="Sport Activator"
                        style="width: 100px;"
                    /> 
                </p>
                <p class="precio text-center">${{ herbalAloe }}.-</p>                
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>
                        Fórmulas enriquecidas con aloe vera, proteína de trigo 
                        hidrolizada y selectos ingredientes botánicos.
                    </li>
                    <li>
                        Dejan el cabello 10 veces más fuerte después de un solo 
                        uso* y reducen al 90% el cabello quebradizo **
                    </li>
                    <li>
                        Ayudan a proteger, reparar y fortalecer el cabello, 
                        dejándolo suave y sedoso.
                    </li>
                    <li>
                        Ayudan a conservar el color del cabello teñido.
                    </li>
                    <li>
                        Libres de sulfatos y parabenos.
                    </li>
                    <li>
                        Fórmulas suaves y seguras, ideales para ser usadas 
                        por toda la familia, todos los días.
                    </li>
                    <li>
                        Fragancia fresca y suave.
                    </li>
                    <p></p>
                    <li>
                        * Al usarse juntos el Shampoo Fortificante junto con el Acondicionador 
                        Fortificante en comparación con el cabello no tratado.
                    </li>
                    <li>
                        ** Resultados basados en un estudio clínico de fortalecimiento y rotura 
                        de cabello realizado por un laboratorio de investigación independiente. 
                        Uso externo únicamente. Evitar contacto con los ojos, en caso de contacto 
                        con los ojos enjuague con bastante agua. Si observa alguna reacción 
                        desfavorable suspenda su uso. En caso persista, consulte a su médico. 
                        Mantenga fuera del alcance de los niños.
                    </li>
                </ul>
                <!-- <div class="box-cremas-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="YSDUE3KYNMZMS">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm box-cremas">
            <div class="box-cremas-header text-center">Herbal Aloe Acondicionador Fortificante 250 ml</div>
            <div class="box-cremas-body">
                <h5 class="box-cremas-title">Shampoo y acondicionador fortificante Herbal Aloe</h5>
                <h6 class="box-cremas-subtitle">Código 2565 </h6>
                <p class="image-h24 text-center"> 
                    <img src="assets/image/acondicionador_fortificante.png" 
                        alt="Herbal Aloe Acondicionador Fortificante"
                        style="width: 100px;"> 
                </p>
                <p class="precio text-center">${{ herbalAloe }}.-</p>                
                <h5 class="text-center">Descripción general</h5>
                <p>Shampoo y acondicionador fortificante Herbal Aloe</p>
                <ul class="text-left">Beneficios:
                    <li>
                        Fórmulas enriquecidas con aloe vera, proteína de trigo hidrolizada y 
                        selectos ingredientes botánicos.
                    </li>
                    <li>
                        Dejan el cabello 10 veces más fuerte después de un solo uso* y reducen 
                        al 90% el cabello quebradizo **
                    </li>
                    <li>
                        Ayudan a proteger, reparar y fortalecer el cabello, dejándolo suave y sedoso.
                    </li>
                    <li>Ayudan a conservar el color del cabello teñido.</li>
                    <li>Libres de sulfatos y parabenos.</li>
                    <li>Fórmulas suaves y seguras, ideales para ser usadas por toda la familia, todos los días.</li>
                    <li>Fragancia fresca y suave.</li>
                    <p></p>
                    <li>
                        * Al usarse juntos el Shampoo Fortificante junto con el Acondicionador Fortificante
                        en comparación con el cabello no tratado.
                    </li>
                    <li>
                        ** Resultados basados en un estudio clínico de fortalecimiento y rotura de cabello realizado 
                        por un laboratorio de investigación independiente. Uso externo únicamente. 
                        Evitar contacto con los ojos, en caso de contacto con los ojos enjuague 
                        con bastante agua. Si observa alguna reacción desfavorable suspenda su 
                        uso. En caso persista, consulte a su médico. Mantenga fuera del alcance 
                        de los niños.
                    </li>
                </ul>
                <!-- <div class="box-cremas-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="H69SHYMMTEQEJ">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>

        <div class="col-sm box-cremas">
            <div class="box-cremas-header text-center">SKIN Tonificador Energizante de Hierbas 50 ml</div>
            <div class="box-cremas-body">
                <h5 class="box-cremas-title">Tónico Energizante de Hierbas</h5>
                <h6 class="box-cremas-subtitle">Código 0767 </h6>
                <p class="image-h24 text-center"> 
                    <img src="assets/image/tonificador_energizante.png" 
                        alt="Tónico Energizante de Hierbas"
                        style="width: 90px;"> 
                </p>
                <p class="precio text-center">${{ skin767}}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>
                        El uso por la mañana y por la noche ayuda a preparar su piel para recibir mejor el 
                        serum, y el hidratante de día o de noche, para mejores resultados.
                    </li>
                    <li>
                        Humecta supiel delicadamente.
                    </li>
                    <li>
                        Su piel se sentirá fresca y limpia sin sequedad ni picazón.
                    </li>
                    <li>
                        Para todo tipo de piel.
                    </li>
                    <li>
                        Sin adición de parabenos.
                    </li>
                    <li>
                        Probado dermatológicamente.
                    </li>
                </ul>
                <!-- <div class="box-cremas-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="83V994YKEVQDN">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>

        <div class="col-sm box-cremas">
            <div class="box-cremas-header text-center">SKIN Crema Humectante Protectora de Día SPF30 30 ml</div>
            <div class="box-cremas-body">
                <h5 class="box-cremas-title">Crema Humectante Protectora de Día de Amplio Espectro FPS30</h5>
                <h6 class="box-cremas-subtitle">Código 0899 </h6>
                <p class="image-h24 text-center"> 
                    <img src="assets/image/crema_humectante.png" 
                        alt="Crema Humectante Protectora de Día de Amplio Espectro FPS30"
                        style="width: 90px;"> 
                </p>
                <p class="precio text-center">${{ skin899 }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <ul class="text-left">Beneficios:
                    <li>
                        Probado para duplicar la hidratación de la piel por ocho horas.*
                    </li>
                    <li>
                        Demuestra que la piel luce más radiante y se siente más suavey 
                        tersa en solo siete días.**
                    </li>
                    <li>
                        Reduce la apariencia de líneas finas y arrugas.
                    </li>
                    <li>
                        Proporciona protección de amplio espectro contra rayos UVA/UVB.
                    </li>
                    <li>
                        Sin adición de parabenos.
                    </li>
                    <li>
                        Probado dermatológicamente.
                    </li>
                    <p></p>
                    <li>
                        *Probado en personas midiendo los niveles de hidratación dela piel 
                        en intervalos de ochohoras. El 100% de las personas mostró niveles 
                        más altos de hidratación en la piel por encima del nivel base después 
                        de ocho horas.
                    </li>
                    <li>
                        **Probado en personas mediante clasificación visual experta en intervalos 
                        de 2, 4 y 7 días.El 100% de las personas mostró una mejora durante 7 días 
                        en tersura, suavidad y fulgor, además de resplandor y luminosidad.
                    </li>
                </ul>
                <!-- <div class="box-cremas-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="B8QKBXCVSMWCW">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm box-kit-cremas">
            <div class="box-cremas-header text-center">KIT Básico SKIN Piel Seca</div>
                <div class="box-cremas-body">
                    <h5 class="box-cremas-title">SKIN Limpiador Relajante de Sábila</h5>
                    <h6 class="box-cremas-subtitle">Código 3293 </h6>
                    <p class="image-h24 text-center"> 
                        <img src="assets/image/kit_basico_piel_seca.png" 
                            alt="KIT Básico SKIN Piel Seca"
                            style="width: 200px;"> 
                    </p>
                    <p class="precio text-center">${{ skin3293 }}.-</p>
                    <h5 class="text-center">Descripción general</h5>
                    <h5>Limpiador Relajante de Aloe</h5>
                    <ul class="text-left">Beneficios:
                        <li>
                            Limpiador a base de aloe con fragancia fresca y suave. 
                            Perfecto para la piel normal o seca.
                        </li>
                        <li>
                            Espuma sedosa y suave que elimina el exceso de grasa, 
                            suciedad y quita el maquillaje ligero de la piel, sin irritarla.
                        </li>
                        <li>
                        </li>
                            La sábila suaviza e hidrata la piel. Sin adición de parabenos, 
                            ni sulfatos.
                        <li>
                            Probado dermatológicamente.N Tonificador Energizante de Hierbas
                        </li>
                    </ul>
                    <h5>SKIN Tonificador Energizante de Hierbas</h5>
                    <ul>Beneficios:
                        <li>
                            El uso por la mañana y por la noche ayuda a preparar su piel para 
                            recibir mejor el serum, y el hidratante de día o de noche, para mejores 
                            resultados.
                        </li>
                        <li>
                            Humecta su piel delicadamente.
                        </li>
                        <li>
                            Su piel se sentirá fresca y limpia sin sequedad ni picazón.
                        </li>
                        <li>
                            Para todo tipo de piel.
                        </li>
                        <li>
                            Sin adición de parabenos.
                        </li>
                        <li>
                            Probado dermatológicamente.
                        </li>
                    </ul>
                    <h5>SKIN Crema Humectante Protectora de Día de Amplio Espectro con FPS 30.</h5>
                    <ul>Beneficios:
                        <li>
                            El uso de día y de noche ayuda a maximizar sus resultados.
                        </li>
                        <li>
                            Reduce la apariencia de líneas finas y arrugas.
                        </li>
                        <li>
                            Probado clínicamente para mostrar una mejora extraordinaria en la suavidad, 
                            tersura, resplandor, fulgor y luminosidad de la piel en solo siete días.*
                        </li>
                        <li>
                            Para todo tipo de piel.
                        </li>
                        <li>
                            Sin adición de parabenos.
                        </li>
                        <li>
                            Probado dermatológicamente.
                        </li>
                    </ul>
                    <!-- <div class="box-cremas-footer text-center">
                        <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                            <input type="hidden" name="cmd" value="_s-xclick">
                            <input type="hidden" name="hosted_button_id" value="LVCC84KT2L5GW">
                            <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                            <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                        </form>
                    </div> -->
                </div>
            </div>
            
        <div class="col-sm box-kit-cremas">
            <div class="box-cremas-header text-center">Kit Básico SKIN Piel Grasa</div>
            <div class="box-cremas-body">
                <h5 class="box-cremas-title">SKIN Limpiador Cítrico para la piel</h5>
                <h6 class="box-cremas-subtitle">Código 3294 </h6>
                <p class="image-h24 text-center"> 
                    <img src="assets/image/kit_basico_piel_grasa.png" 
                        alt="Kit Básico SKIN Piel Grasa"
                        style="width: 200px;"> 
                </p>
                <p class="precio text-center">${{ skin3294 }}.-</p>
                <h5 class="text-center">Descripción general</h5>
                <h5>Limpiador Cítrico</h5>
                <ul class="text-left">Beneficios:
                    <li>
                        Limpiador con toques de naranja y pomelo, con semillas de jojoba. 
                        Perfecto para la piel normal a grasosa.
                    </li>
                    <li>
                        Probado clínicamente para mostrar una reducción en el sebo de la 
                        piel después de un solo uso.*
                    </li>
                    <li>
                        Fórmula con microperlas revitalizantes.
                    </li>
                    <li>
                        Sin adición de parabenos, ni sulfatos.
                    </li>
                    <li>
                        Probado dermatológicamente.
                    </li>
                </ul>
                <h5>SKIN Tonificador Energizante de Hierbas</h5>
                <ul>Beneficios:
                    <li>
                        El uso por la mañana y por la noche ayuda a preparar su piel para 
                        recibir mejor el serum, y el hidratante de día o de noche, para mejores 
                        resultados.
                    </li>
                    <li>
                        Humecta su piel delicadamente.
                    </li>
                    <li>
                        Su piel se sentirá fresca y limpia sin sequedad ni picazón.
                    </li>
                    <li>
                        Para todo tipo de piel.
                    </li>
                    <li>
                        Sin adición de parabenos.
                    </li>
                    <li>
                        Probado dermatológicamente.
                    </li>
                </ul>
                <h5>SKIN Crema Humectante Protectora de Día de Amplio Espectro con FPS 30.</h5>
                <ul>Beneficios:
                    <li>
                        El uso de día y de noche ayuda a maximizar sus resultados.
                    </li>
                    <li>
                        Reduce la apariencia de líneas finas y arrugas.
                    </li>
                    <li>
                        Probado clínicamente para mostrar una mejora extraordinaria en la suavidad, 
                        tersura, resplandor, fulgor y luminosidad de la piel en solo siete días.*
                    </li>
                    <li>
                        Para todo tipo de piel.
                    </li>
                    <li>
                        Sin adición de parabenos.
                    </li>
                    <li>
                        Probado dermatológicamente.
                    </li>
                </ul>
                <!-- <div class="box-cremas-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="J48GGC9EJ5DGA">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>

        <div class="col-sm box-kit-cremas">
            <div class="box-cremas-header text-center">KIT Avanzado SKIN</div>
            <div class="box-cremas-body">
                <h5 class="box-cremas-title">SKIN Limpiador Relajante de Sábila</h5>
                <h6 class="box-cremas-subtitle">Código 3295 </h6>
                <p class="image-h24 text-center"> 
                    <img src="assets/image/kit_avanzado.png" 
                        alt="SKIN Limpiador Relajante de Sábila"
                        style="width: 250px;"> 
                </p>
                <p class="precio text-center">${{ skin3295 }}.-<p>>
                <h5 class="text-center">Descripción general</h5>
                <h5>Limpiador Relajante de Aloe</h5>
                <ul class="text-left">Beneficios:
                    <li>
                        Limpiador a base de aloe con fragancia fresca y suave. 
                        Perfecto para la piel normal o seca.
                    </li>
                    <li>
                        Espuma sedosa y suave que elimina el exceso de grasa, 
                        suciedad y quita el maquillaje ligero de la piel, sin irritarla.
                    </li>
                    <li>
                        La sábila suaviza e hidrata la piel. Sin adición de parabenos, ni sulfatos.
                    </li>
                    <li>
                        Probado dermatológicamente.
                    </li>
                </ul>
                <h5>SKIN Tonificador Energizante de Hierbas</h5>
                <ul>Beneficios:
                    <li>
                        El uso por la mañana y por la noche ayuda a preparar su piel para 
                        recibir mejor el serum, y el hidratante de día o de noche, para mejores 
                        resultados.
                    </li>
                    <li>
                        Humecta su piel delicadamente.
                    </li>
                    <li>
                        Su piel se sentirá fresca y limpia sin sequedad ni picazón.
                    </li>
                    <li>
                        Para todo tipo de piel.
                    </li>
                    <li>
                        Sin adición de parabenos.
                    </li>
                    <li>
                        Probado dermatológicamente.
                    </li>
                </ul>
                <h5>SKIN Crema Humectante Protectora de Día de Amplio Espectro con FPS 30.</h5>
                <ul>Beneficios:
                    <li>
                        El uso de día y de noche ayuda a maximizar sus resultados.
                    </li>
                    <li>
                        Reduce la apariencia de líneas finas y arrugas.
                    </li>
                    <li>
                        Probado clínicamente para mostrar una mejora extraordinaria en la suavidad, 
                        tersura, resplandor, fulgor y luminosidad de la piel en solo siete días.*
                    </li>
                    <li>
                        Para todo tipo de piel.
                    </li>
                    <li>
                        Sin adición de parabenos.
                    </li>
                    <li>
                        Probado dermatológicamente.
                    </li>
                </ul>
                <h5>SKIN Sérum Reductor de Líneas 30 ml.</h5>
                <ul>Beneficios:
                    <li>
                        El uso de día y de noche ayuda a maximizar sus resultados.
                    </li>
                    <li>
                        Reduce la apariencia de líneas finas y arrugas.
                    </li>
                    <li>
                        Probado clínicamente para mostrar una mejora extraordinaria 
                        en la suavidad, tersura, resplandor, fulgor y luminosidad de 
                        la piel en solo siete días.*
                    </li>
                    <li>
                        Para todo tipo de piel.
                    </li>
                    <li>
                        Sin adición de parabenos.
                    </li>
                    <li>
                        Probado dermatológicamente.
                    </li>
                    <p></p>
                    <li>
                        * Probado en personas para comprobar suavidad, tersura, resplandor, 
                        fulgor y luminosidad mediante clasificación experta en intervalos 
                        de dos, cuatro y siete días. El 100% de personas mostró una mejora 
                        en las cinco áreas en siete días.
                    </li>
                </ul>
                <!-- <div class="box-cremas-footer text-center">
                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="cmd" value="_s-xclick">
                        <input type="hidden" name="hosted_button_id" value="D6GJUWCELACN4">
                        <input type="image" src="https://mipesoideal.com/paypal.png" border="0" name="submit" alt="PayPal, la forma rápida y segura de pagar en Internet.">
                        <img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1">
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</div>
